import React, { useEffect, useState } from 'react';
import "./home.css";
import { NavLink } from 'react-router-dom';
import { Button, Grid, TextField, Typography } from '@mui/material';
import HomeLogo from "../../Assets/homelogo.jpeg";
import { Video } from "../../Components/Video";
import TQUIZIMG from "../../Assets/QUIZIMG.png";
import POLLSIMG from "../../Assets/LIVEPOOLSIMAGE.png";
import CONTESTSIMG from "../../Assets/CONTESTSIMG.png";
import HIGHLIGHTSIMG from "../../Assets/HIGHLIGHTIMAGE.png";
import LEADERBOARDIMG from "../../Assets/LEADERBOARD.png";
import FAQSIMG from "../../Assets/FAQSIMAGE.png";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

const Home = () => {
  let token = localStorage.getItem("token");
  let sessionStorageCurrentUserAvatarUrl = JSON.parse(localStorage.getItem("currentUserAvatarUrl"));
  let sessionStorageCurrentUserId = localStorage.getItem("currentUserId");
    const [isProfileImageModel,setIsProfileImageModel] = useState();
    const [imageFile,setImageFile] = useState();
    const [saveButtonLoading,setSaveButtonLoading] = useState(false);

    useEffect(()=>{
      if(sessionStorageCurrentUserAvatarUrl){
        setIsProfileImageModel(false)
      }
      if(!sessionStorageCurrentUserAvatarUrl){
        setIsProfileImageModel(true)
      }
    },[])
    const handleClose = () => {
        setIsProfileImageModel(false);
    };

    const handleUpdateImageChange = (e)=>{
        console.log(e.target.files[0])
        setImageFile(e.target.files[0]);
      }

    const onSaveChanges = () => {
        let formdata = new FormData();
        if(imageFile){
            formdata.append("user[avatar]", imageFile)
        }
        try{
          setSaveButtonLoading(true)
          fetch(
          `https://api.sociana.in/api/v1/users/${sessionStorageCurrentUserId}`,
          {
            method: "PUT",
            body: formdata,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ).then(res =>
          {return(
            console.log(res)
          ),
          res.json()}
          ).then(result=> {
            if(result){
                handleClose();
                setSaveButtonLoading(false);
                setImageFile();
            }
          if(result?.data?.attributes?.avatarUrl){
            return(
              localStorage.setItem("currentUserAvatarUrl",JSON.stringify((result?.data?.attributes?.avatarUrl)))
            )
          }
        })}catch{
          console.log("error")
        };
      };

    return (
        <>
            <Grid container style={{marginBottom:"60px",background:"#00EB5E"}}>
                <Grid style={{ width: "100%", background: "#221c46", padding: "20px 25px",display:"flex",justifyContent:"center" }}>
                    <Grid style={{ width: "400px" }}>
                    <img style={{width:"100%"}} src={HomeLogo} alt="Logo" />
                    </Grid>
                </Grid>
                <Grid style={{ width: "100%", padding: "50px 10px", textAlign: "center" }}>
                    <Video />
                </Grid>
                <div className='rout_container'>
                    <NavLink className="routnavbar" to="/triviaquiz"><Typography variant='h6' style={{fontWeight:"700",fontFamily:'"Roboto Condensed", sans-serif;'}}>Trivia Quiz</Typography><img src={TQUIZIMG} alt='TQUIZIMG'/></NavLink>

                    <NavLink className="routnavbar" to="/livepolls"><Typography variant='h6' style={{fontWeight:"700",fontFamily:'"Roboto Condensed", sans-serif;'}}>Live Polls</Typography><img src={POLLSIMG} alt='POLLSIMG'/></NavLink>

                    <NavLink className="routnavbar" to="/contest"><Typography variant='h6' style={{fontWeight:"700",fontFamily:'"Roboto Condensed", sans-serif;'}}>Contests</Typography><img src={CONTESTSIMG} alt='CONTESTSIMG'/></NavLink>

                    <NavLink className="routnavbar" to="/highlights"><Typography variant='h6' style={{fontWeight:"700",fontFamily:'"Roboto Condensed", sans-serif;'}}>Highlights</Typography><img src={HIGHLIGHTSIMG} alt='HIGHLIGHTSIMG'/></NavLink>

                    <NavLink className="routnavbar" to="/leaderboard"><Typography variant='h6' style={{fontWeight:"700",fontFamily:'"Roboto Condensed", sans-serif;'}}>Leaderboard</Typography><img src={LEADERBOARDIMG} alt='LEADERBOARDIMG'/></NavLink>

                    <NavLink className="routnavbar" to="/faqs"><Typography variant='h6' style={{fontWeight:"700",fontFamily:'"Roboto Condensed", sans-serif;'}}>FAQs</Typography><img src={FAQSIMG} alt='FAQSIMG'/></NavLink>
                </div>
            </Grid>

       <BootstrapDialog 
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isProfileImageModel}
        >
          <DialogActions
            style={{
              minWidth: "100%",
              height: "300px",
              overflow:"hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid style={{width:"100%",textAlign:"center"}}>
            <label style={{fontSize:"25px",fontWeight:"600",color:"#221c46"}}>Upload Profile Picture</label>
            <input
            style={{width:"100%",margin:"25px 0 25px 25%",cursor:"pointer",textAlign:"center"}}
              accept="image/*"
              id="raised-button-file"
              placeholder="Select Image"
              type="file"
              onChange={handleUpdateImageChange}
            />
            {saveButtonLoading ? <Button disabled style={{border:"1px solid #221c46",color:"#221c46"}}>
              Loading... 
            </Button> 
            :
            <Grid style={{width:"100%"}}><Button style={{padding:"8px 15px",border:"1px solid #221c46",cursor:"pointer",margin:"0 10px",color:"#221c46"}} onClick={onSaveChanges}>
              Save changes
            </Button></Grid>}
            <Grid style={{width:"100%"}}><Button style={{padding:"8px 15px",border:"1px solid #221c46",cursor:"pointer",margin:"35px 10px",color:"#221c46"}} onClick={handleClose}>
              Update Later
            </Button></Grid>
            </Grid>
          </DialogActions>
        </BootstrapDialog>
        </>
    )
}

export default Home;