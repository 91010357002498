import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./pool.css";

const Pollone = ({currentData,included,token}) => {

  const [apiSelectedId, setApiSelectedId] = useState({data:[],included:[]});
  const [selecedOptionID, setSelecedOptionID] = useState();
  const [eachOptVoteArray, setEachOptVoteArray] = useState(0);
  // const [totalVote, setTotalVote] = useState();

  useEffect(()=>{
    setEachOptVoteArray(
     currentData?.relationships?.pollPostOptions?.data?.map(el=>{
     return included?.find(elem=>{
        return  el.id == elem.id
        })?.attributes?.answerCount
      }).reduce((accumulator, currentValue) =>{
        return accumulator + currentValue
        }, 0))
    
    },[selecedOptionID])

  console.log("totalvote",eachOptVoteArray);

  const handleSelect=(el)=>{
      if(selecedOptionID == el){
        return "pollselected"
      }
  }


  const handleCheck=(id)=>{
    console.log(id)
    let formdata = new FormData();
    formdata.append("poll_post_answer[poll_post_option_id]", id);
    fetch('https://api.sociana.in/api/v2/oc/146/visitant/poll_post/poll_post_answers', {
                method: 'POST',
                body: formdata,
                headers: {
                    Authorization: `Bearer ${token}`
                }
    }).then(res=> res.json().then((result)=>{
      if(result){
        setApiSelectedId({
            data:result?.data,
            included:result?.included})
    }
    }))
    setSelecedOptionID(id)
  }

  console.log("apiSelectedIdapiSelectedId",currentData,included)
  return (
    <Grid key={currentData.id} style={{minwidth:"300px",maxWidth:"500px",margin:"auto",padding:"14px 20px",background:"#221c46",boxShadow:"2.5px 2.5px 2px 1.5px #ffffff"}}>
            <Grid style={{marginBottom:"10px"}}>
                <Typography variant='h5' style={{ width: "100%", fontSize: "25px", color: "#ffffff", fontWeight: "300",fontFamily: '"Roboto Condensed", sans-serif'}}>{currentData?.attributes?.question}</Typography>
            </Grid>
            <Grid className='options'>
            <Grid className='pooloptions'>
                {
            currentData?.relationships?.pollPostOptions?.data?.map(el=>{
                       return   (<button
                       onClick={()=> handleCheck(el?.id)}
                       key={el.id} 
                      className={currentData?.relationships?.currentUserPollPostAnswer?.data?.id ?el.id == included?.find((elem)=> elem.id == currentData?.relationships?.currentUserPollPostAnswer?.data?.id && elem.type == currentData?.relationships?.currentUserPollPostAnswer?.data?.type )?.attributes?.pollPostOptionId ? "pollselected" : "singleOptionpool" : `singleOptionpool ${selecedOptionID && handleSelect(el.id)}`}
                       disabled={currentData?.relationships?.currentUserPollPostAnswer?.data?.id ? true : 
                        apiSelectedId?.data?.relationships?.currentUserPollPostAnswer?.data?.id ? true : false}>
                        {
                          Math.floor((included?.find((elem)=>elem.id == el.id && elem.type == el.type)?.attributes?.answerCount * 100)/eachOptVoteArray) > 0 ? `${Math.floor((included?.find((elem)=>elem.id == el.id && elem.type == el.type)?.attributes?.answerCount * 100)/eachOptVoteArray)}%    ${included?.find((elem)=>elem.id == el.id && elem.type == el.type)?.attributes?.option}` : included?.find((elem)=>elem.id == el.id && elem.type == el.type)?.attributes?.option
                        }
                        </button>)
                       })
            }
    </Grid>
            </Grid>
            <Grid>
                {eachOptVoteArray > 0 ? <Typography variant='h6' style={{color:"white",fontFamily: "'Roboto Condensed', sans-serif"}}>{
                  `${eachOptVoteArray} votes`
                }</Typography> : <Typography variant='h6' style={{color:"white",fontFamily: "'Roboto Condensed', sans-serif"}}>0 votes</Typography>}
            </Grid>
        </Grid>
  )
}

export default Pollone;