import React,{ useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import QALogo from "../../Assets/homelogo.jpeg";
import leftArrow from "../../Assets/AgendaIcon.png";
// import CircularProgress from '@mui/material/CircularProgress';
import Pollone from './Pollone';


const Livepolls = () => {

  let token = localStorage.getItem("token");
    const [questionData, setQuestionData] = useState({data:[],included:[],links:{},meta:{}});
    const [page, setPage] = React.useState(1);


    const navigate = useNavigate();

    const onclicked = () => {
        navigate("/");
    }

    useEffect(()=>{
        // console.log("page",page)
        try{
            
            fetch(
            `https://api.sociana.in/api/v2/oc/146/visitant/poll_post/get_poll_post_by_question_type?page=${page}&question_type=poll`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then((res)=>{
            return (
                res.json()
            );
        }).then((results)=>{
            if(results){
                setQuestionData({
                    data:questionData?.data.concat(results?.data),
                    included:questionData?.included.concat(results?.included)
                   ,links:results?.links,
                    meta:results?.meta})
            }
        });
    
    }catch (err) {
        console.log(err);
    }
    
    },[page])


    const getNextPage = (str) => {
        const str1 = str.split("=");
        const res = str1[1].split("&");
        return parseInt(res[0], 10);
      };
    
      const onLoadMore = () => {
        if (!!questionData?.links?.next) {
          const pageNumber = getNextPage(questionData?.links?.next);
          setPage(pageNumber);
        }
      };

      const loadMore = React.useMemo(() => {
        return !!questionData?.links?.next ? (
          <Grid textAlign="center" style={{ width: "100%" }}>
            <Button
              style={{
                marginBottom: "20px",
                cursor: "pointer",
                padding: "5px 20px",
                backgroundColor: "#221c46",
                color: "white",
                borderRadius: "10px",
              }}
              onClick={onLoadMore}
            >
              loading more
            </Button>
          </Grid>
        ) : null;
      }, [questionData?.links?.next]);

    return (
        <Grid container  style={{marginBottom:"60px"}}>
            <Grid style={{ width: "100%", background: "#221c46", padding: "20px 25px",display:"flex",justifyContent:"center" }}>
                    <Grid style={{ width: "400px" }}>
                    <img style={{width:"100%"}} src={QALogo} alt="Logo" />
                    </Grid>
                </Grid>
            <Grid style={{ width: "100%", padding: "20px 20px", display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <Button onClick={onclicked} style={{ padding: "10px 10px", textTransform: "none", background: "none", color: "#ffffff", fontSize: "25px", fontWeight: "500", fontFamily: "'Roboto Condensed', sans-serif" }}><img style={{ marginRight: "10px" }} src={leftArrow} alt='<-' /><b style={{color:"#221C46"}}>Live Polls</b></Button>
            </Grid>

    <Grid style={{width:"100%",padding:"0px 10px",marginBottom:"30px"}}>
        {questionData?.data?.map((quedata)=>

        <>
         <Pollone currentData={quedata}  included={questionData?.included} token={token}/>
        <Grid style={{minwidth:"300px",maxWidth:"500px",margin:"auto"}}>
            <Grid style={{width:"80%",margin:"40px auto"}}><hr style={{color:"white"}}/><hr style={{color:"white"}}/></Grid>
        </Grid>
        </>
        )}
    </Grid> 
            {loadMore}
        </Grid>
    )
}

export default Livepolls;