import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Grid } from "@mui/material";

const CarouselView = ({
  pictureUrl = [],
  videoUrl = [],
  videoThumbnailUrls = [],
}) => {
  if (pictureUrl?.length > 0) {
    return (
      <Grid style={{ width: "100%", padding: "0 10px" }}>
        <Carousel showThumbs={false}>
          {pictureUrl?.map((item) => (
            <div>
              <img src={`https://api.sociana.in//${item}`} />
            </div>
          ))}
        </Carousel>
      </Grid>
    );
  }
  if(videoUrl?.length > 0){
    return (
    <Grid style={{ width: "100%", padding: "0 10px" }}>
      <Carousel showThumbs={false}>
        {videoUrl?.map((item, index) => (
          <div>
            <video
              controls
              style={{ width: "100%" }}
              poster={
                videoThumbnailUrls[index]
                  ? `https://api.sociana.in//${videoThumbnailUrls[index]}`
                  : null
              }
            >
              <source
                src={`https://api.sociana.in//${item}`}
                type="video/mp4"
              ></source>
            </video>
          </div>
        ))}
      </Carousel>
    </Grid>
  );
}
return
};

export default CarouselView;
