import React from 'react';
import "./leaderboard.css";
import { useNavigate } from 'react-router-dom';
import {
    Grid, Button, Typography
} from '@mui/material';
import FAQSLogo from "../../Assets/homelogo.jpeg";
import leftArrow from "../../Assets/AgendaIcon.png";
import EmptyDataLeaderboard from "../../Components/EmptyDataLeaderboard";

const Leaderboards = () => {

  // console.log(currentUserId)

  let token = localStorage.getItem("token");
  let currentUserId = localStorage.getItem("currentUserId");

  const [leaderboardData, setLeaderboardData] = React.useState({data:[],links:{}});
  const [selectedData, setSelectedData] = React.useState("");
  const [userData, setUserData] = React.useState({data:[]});
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    try {
      fetch(
        `https://api.sociana.in/api/v2/oc/146/visitant/competition/get_participants_by_competition?competition_id=all`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            return (
              // setSnackbarData({
              //   message: "All competitions loaded successfully.",
              //   type: "success",
              // }),
              res.json()
            );
          }
          return res.json();
        })
        .then((results) => {
          if (results) {
            setUserData({
              data: results?.data,
            });
          }
        });
    } catch (error) {
      // setSnackbarData({
      //   message: "Something Went Wrong.",
      //   type: "error",
      // });
      console.error(error);
    }
  }, [selectedData == "all"]);

  React.useEffect(() => {
    try {
      fetch(
        `https://api.sociana.in/api/v2/oc/146/visitant/competition/competitions?page=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            return (
              // setSnackbarData({
              //   message: "All competitions loaded successfully.",
              //   type: "success",
              // }),
              res.json()
            );
          }
          return res.json();
        })
        .then((results) => {
          if (results) {
            setLeaderboardData({
              data: leaderboardData?.data?.concat(results?.data),
              links: results?.links,
            });
          }
        });
    } catch (error) {
      // setSnackbarData({
      //   message: "Something Went Wrong.",
      //   type: "error",
      // });
      console.error(error);
    }
  }, [page]);

  const SelectedCompetationApiFetch = (id)=>{
    try{
      fetch(
        `https://api.sociana.in/api/v2/oc/146/visitant/competition/get_participants_by_competition?competition_id=${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            return (
              // setSnackbarData({
              //   message: "All competitions loaded successfully.",
              //   type: "success",
              // }),
              res.json()
            );
          }
          return res.json();
        })
        .then((results) => {
          if (results) {
            if (results) {
              setUserData({
                data: results?.data,
              });
            }
          }
        });
    }catch (error) {
      // setSnackbarData({
      //   message: "Something Went Wrong.",
      //   type: "error",
      // });
      console.error(error);
    }
  }

  const getNextPage = (str) => {
    const str1 = str.split("=");
    const res = str1[1].split("&");
    return parseInt(res[0], 10);
  };

  const onLoadMore = React.useMemo(() => {
    if (!!leaderboardData?.links?.next) {
      const pageNumber = getNextPage(leaderboardData?.links?.next);
      setPage(pageNumber);
    }
  },[leaderboardData?.links?.next]);

  const handleChange = (event) => {
    setSelectedData(event.target.value);
    if(event.target.value !== "all"){
      return SelectedCompetationApiFetch(event.target.value);
    }
  }
 
  const navigate = useNavigate();

    const onclicked = () => {
        navigate("/");
    }

    console.log("allll",userData);

  return (
    <Grid container style={{marginBottom:"60px"}}>
            <Grid style={{ width: "100%", background: "#221c46", padding: "20px 25px",display:"flex",justifyContent:"center" }}>
                    <Grid style={{ width: "400px" }}>
                    <img style={{width:"100%"}} src={FAQSLogo} alt="Logo" />
                    </Grid>
                </Grid>
            <Grid style={{ width: "100%", padding: "20px 20px", display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <Button onClick={onclicked} style={{ padding: "10px 10px", textTransform: "none", background: "none", color: "#ffffff", fontSize: "25px", fontWeight: "500", fontFamily: "'Roboto Condensed', sans-serif" }}><img style={{ marginRight: "10px" }} src={leftArrow} alt='<-' /><b style={{color:"#221C46"}}>Leaderboard</b></Button>
            </Grid>
            <Grid className='leaderboard_container'>
              <Grid className='leaderboard_btns'>
                <Typography style={{width:"25%",fontSize:"18px",fontWeight:"500",fontFamily:"'Roboto Condensed', sans-serif",color:"#221C46",padding:"10px 8px"}}>Sr.No.</Typography>
                <Typography style={{width:"50%",textAlign:"start",fontSize:"18px",fontWeight:"500",fontFamily:"'Roboto Condensed', sans-serif",color:"#221C46"}}>Name</Typography>
                <select className='leaderboard_select_tag' value={selectedData} onChange={handleChange} defaultValue={{value:"all",label:"Total"}}>
                  <option value="all">Total</option>
                  {leaderboardData?.data?.length > 0 && leaderboardData?.data?.map(el=>{
                    return <option style={{fontSize:"18px",fontWeight: 500,fontFamily: "'Roboto Condensed', sans-serif;"}} value={el?.id}>{el?.attributes?.name}</option>
                  })}
                </select>
              </Grid>
              <Grid className='leaderboard_container'>
                {
                //   Object.values(userData?.data?.find(el=> el.user_id == currentUserId)).length > 0 && Object.keys(userData?.data?.find(el=> el.user_id == currentUserId)).length > 0 && 
                //   <Grid className='leaderboard_data'>
                //     <Typography style={{width:"25%",fontSize:"18px",fontWeight:"500",fontFamily:"'Roboto Condensed', sans-serif",color:"#221C46",padding:"10px 8px"}}>{}</Typography>
                // <Typography style={{width:"50%",textAlign:"start",fontSize:"18px",fontWeight:"500",fontFamily:"'Roboto Condensed', sans-serif",color:"#221C46"}}>Name</Typography>
                // <Typography style={{width:"25%",fontSize:"18px",fontWeight:"500",fontFamily:"'Roboto Condensed', sans-serif",color:"#221C46",padding:"10px 8px"}}>Rank</Typography>
                //   </Grid>
                userData?.data?.length > 0 ? userData?.data?.map((item,index)=>{
                  if(item.user_id == currentUserId){
                    return  <Grid className='leaderboard_data'>
                   <Typography style={{width:"25%",fontSize:"18px",fontWeight:"500",fontFamily:"'Roboto Condensed', sans-serif",color:"#221C46",padding:"10px 8px"}}>{index+1}</Typography>
                 <Typography style={{width:"50%",textAlign:"start",fontSize:"18px",fontWeight:"500",fontFamily:"'Roboto Condensed', sans-serif",color:"#221C46",paddingRight:"10px"}}>{item?.user_name}</Typography>
                 <Typography style={{width:"25%",fontSize:"18px",fontWeight:"500",fontFamily:"'Roboto Condensed', sans-serif",color:"#221C46",padding:"10px 8px"}}>{item?.participant_score}</Typography>
                   </Grid>
                  }}): <EmptyDataLeaderboard />
                }
                {
                userData?.data?.length > 0 && userData?.data?.map((item,index)=>{
                   return <Grid className={index % 2 == 0 ? "even_data" : 'odd_data'}>
                   <Typography className='col1'>{index+1}</Typography>
                 <Typography className='col2'>{item?.user_name}</Typography>
                 <Typography className='col3'>{item?.participant_score}</Typography>
                   </Grid>
                  })
                }
              </Grid>
            </Grid>
    </Grid>
  )
}

export default Leaderboards;
