import React, { useState } from 'react';
import "./quiz.css";
import { Grid, Snackbar, Alert } from '@mui/material';

const Quizone = ({currentData,included,correct,setOptionSelectedClear,optionSelectedClear,totalCorrect,
    setTotalCorrect,
    totalInCorrect,
    setTotalInCorrect,token}) => {

        const [snackbarData, setSnackbarData] = useState({
            message: '',
            type: 'success'
        });
    const handleSelect=(el)=>{
        if(optionSelectedClear === el && optionSelectedClear === correct[0]?.id){
            return "select";
        }else if(optionSelectedClear === el && optionSelectedClear  !==  correct[0]?.id){
                    return "wrong";
        }else if(el ===  correct[0]?.id){
                    return "select";
        }
    }
    
    const handleCheck = (id)=>{
        console.log(id)
        let formdata = new FormData();
        formdata.append("poll_post_answer[poll_post_option_id]", id);
        fetch('https://api.sociana.in/api/v2/oc/146/visitant/poll_post/poll_post_answers', {
                    method: 'POST',
                    body: formdata,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
        }).then((res)=>{
            if(res.status == 422){
                return (
                    setSnackbarData({
                        message: 'Poll answer already submitted for post.',
                        type: 'error'
                    })
                );
            }
        })
        setOptionSelectedClear(id)
        if(id === correct[0]?.id){ 
            setTotalCorrect(totalCorrect + 1)
        }else if(id !== correct[0]?.id){
            setTotalInCorrect(totalInCorrect + 1)
        }
    }

    const handleClose = () => {
        setSnackbarData({
            message: '',
            type: 'success'
        });
    };

    // console.log(currentData,included);


      return( 
        <>
         <Grid className='options'>
                {
            currentData?.relationships?.pollPostOptions?.data?.map(el=>{
                       return   (<button 
                           onClick={()=> handleCheck(el.id)}
                           className={currentData?.relationships?.currentUserPollPostAnswer?.data?.id ? el.id === correct[0]?.id ? "singleOption select" : el.id == included?.find(item=> item.id == currentData?.relationships?.currentUserPollPostAnswer?.data?.id)?.attributes?.pollPostOptionId ? "singleOption wrong" : "singleOption" : `singleOption ${optionSelectedClear && handleSelect(el.id)}`}
                           key={el.id}
                           disabled={currentData?.relationships?.currentUserPollPostAnswer?.data?.id ? true : optionSelectedClear}
                       >
                        {
                           included?.find((elem)=>elem.id == el.id && elem.type == el.type)?.attributes?.option
                        }
                        </button>)
                       })
            }
            </Grid>
            <Snackbar
                open={!!snackbarData.message}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleClose}
                style={{ border: '2px solid black', borderRadius: 5 }}
                message={snackbarData.message}>
                <Alert severity={snackbarData.type}>{snackbarData.message}</Alert>
            </Snackbar>
         </>
      )

}

export default Quizone;
