import React, { useState, useEffect } from 'react';
import "./login.css";
import Logo from "../../Assets/homelogo.jpeg";
import { Grid, Typography, Box, TextField, Button, Snackbar, Alert } from '@mui/material';
import Btn from '../../Components/Button';
import LeftIcon from "../../Assets/LeftIcon.png";
import Home from "../Home";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import { BrowserView, MobileView, isMobile } from "react-device-detect";


const uuid = uuidv4();

const validateOtpData = (values,isRegisteredUser) => {
    const error = {};
    if (!values.otp) {
        error.otp = 'OTP is required';
    }
    if (isRegisteredUser == false && !values.name) {
        error.name = 'Name is required';
    }
    return error;
};

const LoginOtp = ({ onclicked, loginFormData, otpData, PERSONALIZED_APP_ID }) => {

    const [tokkenData, SetTokkenData] = useState("")

    const [verificationFormData, setVerificationFormData] = useState({ otp: "", name:"" })
    const [errorMessages, setErrorMessages] = useState({});
    const [snackbarData, setSnackbarData] = useState({
        message: '',
        type: 'success'
    });

    const navigate = useNavigate();

    const joinOccasion = (token,data) => {
        console.log(token);
        try {
            fetch(
                `https://api.sociana.in/api/v2/custom_app/oc/my_occasions?app_id=${PERSONALIZED_APP_ID}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
                .then((res) => {
                    if (res.status == 401) {
                        return (
                            setSnackbarData({
                                message: 'Access denied.',
                                type: 'error'
                            }),
                            res.json()
                        );
                    } else if (res.status == 200 || res.status == 201) {
                        return (
                            setSnackbarData({
                                message: 'Login Success.',
                                type: 'success'
                            }),
                            res.json()
                        );
                    }
                    return setSnackbarData({
                        message: 'Somthing Went Wrong.',
                        type: 'error'
                    });
                })
                .then((res) => {
                    if (res && !res.error && token) {
                        SetTokkenData(token);
                        localStorage.setItem("login",true);
                        localStorage.setItem("token", token);
                        localStorage.setItem("currentUserId",  data?.id);
                        localStorage.setItem("currentUserName", data?.attributes?.name);
                        localStorage.setItem("currentUserAvatarUrl",  JSON.stringify(data?.attributes?.avatarUrl) );
                        navigate("/")
                    }
                });
        } catch (err) {
            setSnackbarData({
                message: 'Somthing Went Wrong.',
                type: 'error'
            });
            console.log(err);
        }
    };

    const handleChange = (e) => {
        try {
            const OtpValue = e?.target?.value;
            const inputName = e?.target?.name;
            if (Object.keys(errorMessages).length > 0) {
                setErrorMessages({});
            }
            setVerificationFormData({
                ...verificationFormData,
                [inputName]: OtpValue
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnProceed = () => {
        try {
            const validateObj = validateOtpData(verificationFormData,otpData.isRegisteredUser);
            if (Object.keys(validateObj).length > 0) {
                setErrorMessages(validateObj);
                return;
            } else {
                setErrorMessages({});
                // setIsLoading(true);

                const bodyforRegisterUser = {
                    login: {
                        email_or_mobile: loginFormData.loginData,
                        verification_code: verificationFormData.otp,
                        device_model:isMobile? "Mobile View" : "Desktop View",
                        uuid:uuid,
                        fcm_token:uuid,
                        platform:"Website"
                    }
                };
                const bodyforUnRegisterUser = {
                    login: {
                        email_or_mobile: loginFormData.loginData,
                        verification_code: verificationFormData.otp,
                        device_model:isMobile? "Mobile View" : "Desktop View",
                        uuid:uuid,
                        fcm_token:uuid,
                        platform:"Website"
                    },
                    user: {
                        first_name: verificationFormData.name.substring(0, verificationFormData.name.indexOf(" ")),
                        last_name: verificationFormData.name.substring(verificationFormData.name.indexOf(' ') + 1)
                    }
                };

                fetch('https://api.sociana.in/api/v3/sessions', {
                    method: 'POST',
                    body:
                        otpData.isRegisteredUser === true
                            ? JSON.stringify(bodyforRegisterUser)
                            : JSON.stringify(bodyforUnRegisterUser),
                    headers: { 'Content-Type': 'application/json' }
                })
                    .then((response) => {
                        if (response.status == 422) {
                            return (
                                setSnackbarData({
                                    message: 'Please enter correct OTP.',
                                    type: 'error'
                                }),
                                response.json()
                            );
                        }
                        return response.json()
                    })
                    .then((results) => {
                        console.log("result...", results);
                        if (results?.meta?.token && results?.data) {
                            // console.log("calloccasionapi", results?.meta?.token);
                            // localStorage.setItem("login",true);
                            // localStorage.setItem("token", results?.meta?.token);
                            // localStorage.setItem("currentUserId", results?.data?.id);
                            // localStorage.setItem("currentUserName", results?.data?.attributes?.name);
                            // localStorage.setItem("currentUserAvatarUrl", results?.data?.attributes?.avatarUrl );
                            joinOccasion(results.meta.token,results.data);
                        }
                    });
            }
        } catch (error) {
            setSnackbarData({
                message: 'Somthing Went Wrong.',
                type: 'error'
            });
            console.log(error);
        }
    };

    const handleClose = () => {
        setSnackbarData({
            message: '',
            type: 'success'
        });
    };


    return (
        <>
            {tokkenData ? <Home /> : <Grid container className='login_container' style={{ width: "100%", height: "100vh", background: "var(--bg)", margin:"0"}}>
                <Grid style={{ width: "100%", textAlign: "center" }}>
                <Grid style={{ width: "100%", background: "#221c46", padding: "20px 25px",display:"flex",justifyContent:"center" }}>
                    <Grid style={{ width: "400px" }}>
                    <img style={{width:"100%"}} src={Logo} alt="Logo" />
                    </Grid>
                </Grid>
                    <Grid className='login_data'>
                        <Typography variant='h4' style={{color:"#221c46"}}>
                            Verify your Email
                        </Typography>
                        <Typography variant='subtitle1' style={{ margin: "10px 0" }}>
                            <b>Code is sent to <span style={{ color: "#221C46" }}>{loginFormData.loginData}</span></b>
                        </Typography>
                        <Grid style={{ textAlign: "start", marginTop: "20px" }}>
                            <Box
                                component="form"
                                sx={{
                                    '& > :not(style)': { width: '100%', background: "#ffffff", borderRadius: "4px", margin: "10px 0" },
                                }}
                            >
                                <TextField id="outlined-basic" variant="outlined" onChange={handleChange} value={verificationFormData.otp} name="otp" />
                            </Box>
                            <Typography variant='subtitle' style={{ color: "red" }}>
                                {errorMessages.otp}
                            </Typography>
                            {otpData.isRegisteredUser === false && <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { width: '100%', background: "#ffffff", borderRadius: "4px", margin: "10px 0 3px 0" },
                                        }}
                                    >
                                        <TextField id="outlined-basic" variant="outlined" onChange={handleChange} value={verificationFormData.name} name="name" placeholder='Enter your Name' />
                                    </Box>}
                                    {otpData.isRegisteredUser === false && <Typography variant='subtitle' style={{ color: "red" }}>
                                        {errorMessages.name}
                                    </Typography>}
                        </Grid>
                        <Grid>
                            <Typography variant="button">
                                Didn’t receive code?<Button><b style={{ color: "#221C46" }}>Try Again</b></Button>
                            </Typography>
                        </Grid>
                        <Btn btnName="Verify & Proceed" onclicked={handleOnProceed} />
                        <Grid style={{ width: "100%" }}>
                            <Button onClick={onclicked} style={{ width: "150px", padding: "10px 10px", textTransform: "none", background: "#F2EBD8", color: "#000000", opacity: "70%", fontWeight: "700" }}><img style={{ marginRight: "5px" }} src={LeftIcon} alt='<-' />Go Back</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            }
            <Snackbar
                open={!!snackbarData.message}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleClose}
                style={{ border: '2px solid black', borderRadius: 5 }}
                message={snackbarData.message}>
                <Alert severity={snackbarData.type}>{snackbarData.message}</Alert>
            </Snackbar>
        </>
    )
}

export default LoginOtp;