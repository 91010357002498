import React, { useState } from 'react';
import "./faqs.css";
import { useNavigate } from 'react-router-dom';
import {
    Grid, Button, Typography
} from '@mui/material';
import FAQSLogo from "../../Assets/homelogo.jpeg";
import leftArrow from "../../Assets/AgendaIcon.png";
import FaqsBtnDetails from '../../Components/FaqsBtnDetails';

const FAQs = () => {

  // const [btnTabIndex, setBtnTabIndex] = useState(0);

  const navigate = useNavigate();

    const onclicked = () => {
        navigate("/");
    }

    const faqsData = [
      {ques:"How early should I arrive at the airport before my flight?",ans:"It is recommended to arrive at least 2-3 hours before a domestic flight."},
      {ques:"Is checking in online for the flight mandatory?",ans:"Web check-in is not mandatory for domestic flights in India, but it is highly recommended. Passengers have the option to check in at the airport as well. However, the web check-in facility provides several advantages, such as saving time and avoiding long queues at the airport. To get the most accurate and up-to-date information, please check the official website of the airline you are flying with or contact their customer service."},
      {ques:"What are the security procedures at the airport?",ans:"Passengers are required to go through security screening. Follow the guidelines provided by airport security personnel."},
      {ques:"Are there any restrictions on carrying certain items in hand luggage?",ans:"Yes, there are restrictions on liquids, sharp objects, and other items. Familiarize yourself with the airline's and airport's rules regarding hand luggage."},
      {ques:"Which hotel are we staying at?",ans:"Le Méridien Kochi, Kerala"},
      {ques:"How can I provide feedback on the conference?",ans:"We value your feedback. A post-conference survey will be sent to all attendees, and we encourage you to share your thoughts to help improve future conferences."},
      {ques:"Can you share Kochi Emergency helplines with me?",ansopt:["Ambulance - 108","Fire Station - 101","Police Station – 100","Women Helpline - 1091"]}
    ]

  return (
    <Grid container style={{marginBottom:"60px",background:"#ffffff"}}>
            <Grid style={{ width: "100%", background: "#221c46", padding: "20px 25px",display:"flex",justifyContent:"center" }}>
                    <Grid style={{ width: "400px" }}>
                    <img style={{width:"100%"}} src={FAQSLogo} alt="Logo" />
                    </Grid>
                </Grid>
            <Grid style={{ width: "100%", padding: "20px 20px", display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <Button onClick={onclicked} style={{ padding: "10px 10px", textTransform: "none", background: "none", color: "#ffffff", fontSize: "25px", fontWeight: "500", fontFamily: "'Roboto Condensed', sans-serif" }}><img style={{ marginRight: "10px" }} src={leftArrow} alt='<-' /><b style={{color:"#221C46"}}>FAQs</b></Button>
            </Grid>
            <Grid className='faqs_main'>
              <Grid className='faqs_container'>
                <FaqsBtnDetails faqsData={faqsData}/>
                {/* <Grid className='faqs_btns'>
                {btnTabIndex === 0 ?
                <Button onClick={()=> setBtnTabIndex(0)} style={{fontSize:"20px",color:"#221C46",fontWeight:"500",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif',background:"white"}}>Registration</Button>
                : <Button onClick={()=> setBtnTabIndex(0)} style={{fontSize:"20px",color:"#221C46",fontWeight:"500",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif'}}>Registration</Button>}

                {btnTabIndex === 1 ?
                <Button onClick={()=> setBtnTabIndex(1)} style={{fontSize:"20px",color:"#221C46",fontWeight:"500",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif',background:"white"}}>Competition</Button>
                : <Button onClick={()=> setBtnTabIndex(1)} style={{fontSize:"20px",color:"#221C46",fontWeight:"500",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif'}}>Competition</Button>}

                {btnTabIndex === 2 ? <Button onClick={()=> setBtnTabIndex(2)} style={{fontSize:"20px",color:"#221C46",fontWeight:"500",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif',background:"white"}}>Travel</Button> 
                : <Button onClick={()=> setBtnTabIndex(2)} style={{fontSize:"20px",color:"#221C46",fontWeight:"500",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif'}}>Travel</Button>}

                {btnTabIndex === 3 ? <Button onClick={()=> setBtnTabIndex(3)} style={{fontSize:"20px",color:"#221C46",fontWeight:"500",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif',background:"white"}}>Accommodation</Button>
                : <Button onClick={()=> setBtnTabIndex(3)} style={{fontSize:"20px",color:"#221C46",fontWeight:"500",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif'}}>Accommodation</Button>}
                </Grid> */}
              </Grid>
              {/* <Grid className='btn_itms_container'>
                  {
                    btnTabIndex === 0 && <FaqsBtnDetails paragrafArray={Registration}/> 
                  }
                  {
                    btnTabIndex === 1 && <FaqsBtnDetails paragrafArray={Competition}/> 
                  }
                  {
                    btnTabIndex === 2 && <FaqsBtnDetails paragrafArray={Travel}/> 
                  }
                  {
                    btnTabIndex === 3 && <FaqsBtnDetails paragrafArray={Accommodation}/> 
                  }
                </Grid> */}
            </Grid>
     </Grid>
  )
}

export default FAQs;
