import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Button, Grid, Typography } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const validateMediaData = (values, type) => {
  const error = {};
  if (!values.Photo && type == "image") {
    error.Photo = "Please select a Photo";
  }
  if (!values.Video && type == "video") {
    error.Video = "Please select a Video";
  }
  return error;
};

const HighlightModel = ({
  isMediaModelOpen,
  fileType,
  handleMediaModelClose,
  token,
  seIsMedianModelOpen,
  mediableType,
  mediableId,
  isImage,
  setDisplayImageData,
  displayImageData,
  setDisplayVideoData,
  displayVideoData,
}) => {
  const [media, setMedia] = useState({ Photo: null, Video: null });
  const [FileName, setFileName] = useState([]);
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleChange = (e) => {
    try {
      let value;
      let name;
      if (e) {
        if (e.target.name === "Photo") {
          if (e.target.files.length <= 5) {
            value = e.target.files;
            name = e.target.name;
          } else {
            alert("select only 5 images");
            value = e.target.value = "";
            return false;
          }
        }
        if (e.target.name === "Video") {
          value = e.target.files;
          name = e.target.name;
        }
        const HighlightValue = value;
        const inputName = name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setMedia({
          ...media,
          [inputName]: HighlightValue,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadMedia = async () => {
    const validateObj = validateMediaData(media, fileType);
    if (Object.keys(validateObj).length > 0) {
      setErrorMessages(validateObj);
    } else {
      const formData = new FormData();
      if (media.Photo) {
        setSaveButtonLoading(true);
        let EmptyArry = [];
        let newAddedPhoto = [];
        Object.values(media.Photo).forEach(async (file, index) => {
          try {
            formData.append("media[file]", file);
            formData.append("media[mediable_type]", mediableType);
            formData.append("media[mediable_id]", mediableId);
            const Response = await fetch(
              `https://api.sociana.in/api/v2/oc/146/visitant/gallary/media`,
              {
                method: "POST",
                body: formData,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (Response.status == 200 || Response.status == 201) {
              setFileName((prev) => [...prev, file.name]);
              EmptyArry.push(file.name);
            } else {
              setSaveButtonLoading(false);
            }
            const result = await Response.json();
            newAddedPhoto.unshift(result?.data);
            if (isImage == 1) {
              setDisplayImageData({
                ...displayImageData,
                data: [...newAddedPhoto, ...displayImageData.data],
                included: [...result?.included],
              });
            }
            console.log("newArr", newAddedPhoto);
            if (index == media.Photo.length - 1) {
              if (EmptyArry.length == media.Photo.length) {
                setSaveButtonLoading(false);
                seIsMedianModelOpen(false);
                setMedia({ Photo: null, Video: null });
                // window.location.reload(false);
                // getAllDataApi(1,1)
              }
            }
          } catch {
            console.log("error");
          }
        });
      }
      if (media.Video) {
        let newAddedVideo = [];
        try {
          setSaveButtonLoading(true);
          formData.append("media[file]", media.Video[0]);
          formData.append("media[mediable_type]", mediableType);
          formData.append("media[mediable_id]", mediableId);
          fetch(`https://api.sociana.in/api/v2/oc/146/visitant/gallary/media`, {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              if (res.status == 200 || res.status == 201) {
                return (
                  setFileName([Object.values(media.Video)[0].name]), res.json()
                );
              }
            })
            .then((result) => {
              if (result.data) {
                newAddedVideo.unshift(result?.data);
                if (isImage == 2) {
                  return (
                    setDisplayVideoData({
                      ...displayVideoData,
                      data: [...newAddedVideo, ...displayVideoData.data],
                      included: [...result?.included],
                    }),
                    setSaveButtonLoading(false),
                    seIsMedianModelOpen(false),
                    setMedia({ Photo: null, Video: null })
                    // getAllDataApi(1,2)
                  );
                }
              }
            });
        } catch {
          console.log("error");
        }
      }
    }
  };

  return (
    <BootstrapDialog
      onClose={handleMediaModelClose}
      aria-labelledby="customized-dialog-title"
      open={isMediaModelOpen && isMediaModelOpen}
    >
      <DialogActions
        style={{
          minWidth: "100%",
          padding: "25px 15px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 0",
        }}
      >
        <Grid
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            style={{
              width: "100%",
              background: "#221C46",
              padding: "10px 5px",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                color: "white",
                fontSize: "20px",
                fontWeight: "500",
                fontFamily: "'Roboto Condensed', sans-serif",
              }}
            >
              Upload
            </Typography>
          </Grid>
          <Grid
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              margin: "20px 0",
              padding: "0 20px",
            }}
          >
            {fileType == "image" && (
              <label
                style={{
                  fontSize: "18px",
                  margin: "8px 0 12px 0",
                  fontFamily: "'Roboto Condensed', sans-serif",
                  color: "#221C46",
                  fontWeight: "600",
                }}
              >
                Select Photo
              </label>
            )}
            {fileType == "image" && (
              <input
                style={{ width: "100%", marginBottom: "15px" }}
                accept="image/*"
                id="raised-button-file"
                placeholder="Select Image"
                type="file"
                multiple
                name="Photo"
                onChange={handleChange}
              />
            )}
            {errorMessages.Photo && fileType == "image" && (
              <Grid>
                <Typography style={{ color: "red", marginTop: "5px 0 15px 0" }}>
                  {errorMessages.Photo}
                </Typography>
              </Grid>
            )}
            {fileType == "video" && (
              <label
                style={{
                  fontSize: "18px",
                  margin: "8px 0 12px 0",
                  fontFamily: "'Roboto Condensed', sans-serif",
                  color: "#221C46",
                  fontWeight: "600",
                }}
              >
                Select Video
              </label>
            )}
            {fileType == "video" && (
              <input
                style={{ width: "100%" }}
                accept="video/mp4"
                id="raised-button-file"
                placeholder="Select Video"
                type="file"
                name="Video"
                onChange={handleChange}
              />
            )}
            {errorMessages.Video && fileType == "video" && (
              <Grid>
                <Typography style={{ color: "red", marginTop: "5px 0 15px 0" }}>
                  {errorMessages.Video}
                </Typography>
              </Grid>
            )}
            {media?.Photo?.length > 0 && media?.Photo ? (
              Object.values(media?.Photo)?.map((item) => (
                <Grid style={{ width: "100%", padding: 5, margin: "5px 0" }}>
                  {isMediaModelOpen ? (
                    <Typography
                      style={{
                        width: "100%",
                        border: "1px solid #00eb5e",
                        padding: "5px 8px",
                        borderRadius: "10px",
                        fontFamily: '"Roboto Condensed", sans-serif',
                      }}
                    >
                      {item?.name}{" "}
                      {FileName?.length > 0 &&
                        FileName?.map((el) => {
                          if (el == item?.name) {
                            return (
                              <span style={{ float: "right" }}>
                                <CheckRoundedIcon
                                  style={{ color: "#00EB5E" }}
                                />
                              </span>
                            );
                          } else {
                            <span style={{ float: "right" }}>
                              <PriorityHighRoundedIcon
                                style={{ color: "red" }}
                              />
                            </span>;
                          }
                        })}
                    </Typography>
                  ) : (
                    setMedia({ Photo: null, Video: null })
                  )}
                </Grid>
              ))
            ) : (
              <></>
            )}

            {media?.Video?.length > 0 && media?.Video ? (
              Object.values(media?.Video)?.map((item) => (
                <Grid style={{ width: "100%", padding: 5, margin: "5px 0" }}>
                  {isMediaModelOpen ? (
                    <Typography
                      style={{
                        width: "100%",
                        border: "1px solid #00eb5e",
                        padding: "5px 8px",
                        borderRadius: "10px",
                        fontFamily: '"Roboto Condensed", sans-serif',
                      }}
                    >
                      {item?.name}{" "}
                      {FileName?.length > 0 &&
                        FileName?.map((el) => {
                          if (el == item?.name) {
                            return (
                              <span style={{ float: "right" }}>
                                <CheckRoundedIcon
                                  style={{ color: "#00EB5E" }}
                                />
                              </span>
                            );
                          } else {
                            <span style={{ float: "right" }}>
                              <PriorityHighRoundedIcon
                                style={{ color: "red" }}
                              />
                            </span>;
                          }
                        })}
                    </Typography>
                  ) : (
                    setMedia({ Photo: null, Video: null })
                  )}
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        {saveButtonLoading ? (
          <Button
            style={{
              width: "70%",
              background: "#00EB5E",
              color: "#221C46",
              padding: "6px 2px",
              marginBottom: "20px",
              fontFamily: "'Roboto Condensed', sans-serif",
              color: "#221C46",
              fontWeight: "700",
              fontSize: "18px",
              textTransform: "none",
            }}
            disabled
          >
            Loading...
          </Button>
        ) : (
          <Button
            style={{
              width: "70%",
              background: "#00EB5E",
              padding: "6px 2px",
              marginBottom: "20px",
              fontFamily: "'Roboto Condensed', sans-serif",
              color: "#221C46",
              fontWeight: "700",
              fontSize: "18px",
              textTransform: "none",
            }}
            onClick={handleUploadMedia}
          >
            Upload
          </Button>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};

export default HighlightModel;
