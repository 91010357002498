import React from "react";
import EmptyIcon from "../Assets/EmptyIcon.png";

const EmptyDataLeaderboard = () => {
  return (
    <div
    >
      <img style={{ marginRight: "5px" }} src={EmptyIcon} alt="x" /> <span style={{color:"white"}}>There is no
      data...</span>
    </div>
  );
};

export default EmptyDataLeaderboard;