import { useState } from "react";

const ReadMoreReadLess = ({ limit, children }) => {
  const [isReadMoreShown, setReadMoreShown] = useState(false);

  const toggleBtn = () => {
    setReadMoreShown((prevState) => !prevState);
  };
  return (
    <>
      {isReadMoreShown ? children : children?.substr(0, limit)}
      {children?.length > limit ? (
        !isReadMoreShown ? (
          <span style={{ marginTop: "10px" }}>
            <button
              onClick={toggleBtn}
              style={{
                border: "none",
                color: "blue",
                backgroundColor: "transparent",
                padding: "0",
                cursor: "pointer",
              }}
            >
              Read more...
            </button>
          </span>
        ) : (
          <span style={{ marginTop: "10px" }}>
            <button
              onClick={toggleBtn}
              style={{
                border: "none",
                color: "blue",
                backgroundColor: "transparent",
                padding: "0",
                cursor: "pointer",
              }}
            >
              Read less...
            </button>
          </span>
        )
      ) : (
        ""
      )}
    </>
  );
};
export default ReadMoreReadLess;
