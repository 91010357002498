import React from "react";
import "./timeline.css";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Snackbar, Alert, Box, Fab, Typography } from "@mui/material";
import TIMELINELOGO from "../../Assets/homelogo.jpeg";
import LEFT_ARROW from "../../Assets/TimeLine/left_arrow.png";
import EmptyData from "../../Components/EmptyData";
import PostCard from "../../Components/PostCard";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const validatePostData = (values) => {
  const error = {};
  if (!values.Pictures && !values.Video) {
    if(!values.Caption){
      error.Caption = "Caption is required"
      error.Media = "Media is required"
    }
  }
  return error;
};

const Timeline = () => {

  let token = localStorage.getItem("token");
  const [page, setPage] = React.useState(1);
  const [postData, setPostData] = React.useState({
    data: [],
    included: [],
    links: {},
  });
  const [snackbarData, setSnackbarData] = React.useState({
    message: "",
    type: "success",
  });
  const [editOpen, setEditOpen] = React.useState(false);
  const [createData, setCreateData] = React.useState({Pictures:null,Video:null,Caption:""});
  const [errorMessages, setErrorMessages] = React.useState({});
  const [saveButtonLoading,setSaveButtonLoading] = React.useState(false);

  const navigate = useNavigate();

  

  React.useEffect(() => {
    try {
      fetch(
        `https://api.sociana.in/api/v1/oc/146/get_posts_with_pagination?page=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            return (
              setSnackbarData({
                message: "Posts loaded successfully.",
                type: "success",
              }),
              res.json()
            );
          }
          return res.json();
        })
        .then((results) => {
          if (results) {
            setPostData({
              data: postData?.data.concat(results?.data),
              included: postData?.included.concat(results?.included),
              links: results?.links,
            });
          }
        });
    } catch (error) {
      setSnackbarData({
        message: "Something Went Wrong.",
        type: "error",
      });
      console.error(error);
    }
  }, [page]);

  const handleClose = () => {
    setSnackbarData({
      message: "",
      type: "success",
    });
  };

  const onclicked = () => {
    navigate("/");
  };

  const getNextPage = (str) => {
    const str1 = str.split("=");
    const res = str1[1].split("&");
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (!!postData?.links?.next) {
      const pageNumber = getNextPage(postData?.links?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = React.useMemo(() => {
    return !!postData?.links?.next ? (
      <Grid textAlign="center" style={{ width: "100%" }}>
        <Button
          style={{
            marginBottom: "20px",
            cursor: "pointer",
            padding: "5px 20px",
            backgroundColor: "#221c46",
            color: "white",
            borderRadius: "10px",
          }}
          onClick={onLoadMore}
        >
          loading more
        </Button>
      </Grid>
    ) : null;
  }, [postData?.links?.next]);

  const handlePostFloatingButtonOpen = ()=>{
    setEditOpen(true);
  }

  const handlePostFloatingButtonClose = () => {
    setEditOpen(false);
    setCreateData({Pictures:null,Video:null,Caption:""});
    setErrorMessages({})
  };

  const handleChange = (e) => {
    try {
      let value;
      let name;
      if(e){
        if(e.target.name === "Pictures"){
          if(e.target.files.length <= 5) {
            value = e.target.files;
          name = e.target.name;
          } else {
            alert("select only 5 images")
            value= e.target.value = "";
            return false;
          }
        }
        if(e.target.name === "Video"){
          value = e.target.files;
          name = e.target.name;
        }
        if(e.target.name === "Caption"){
          value = e.target.value;
          name = e.target.name;
        }
        const TimelineValue = value;
        const inputName = name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setCreateData({
          ...createData,
          [inputName]: TimelineValue
        });
      }
       
    } catch (error) {
        console.error(error);
    }
  }


  const handleCreatePost= ()=>{
    try{
      setSaveButtonLoading(true)
      const validateObj = validatePostData(createData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      }else{
      const formData = new FormData();
      if(createData.Pictures){
        Object.values(createData.Pictures).forEach((file) => {
            formData.append(
              "post[pictures][]",file
            );
        });
      }
      if(createData.Video){
            formData.append(
              "post[videos][]",createData.Video[0]
            );
      }
      if(createData.Caption){
            formData.append(
              "post[message]",createData.Caption
      );
      }
      fetch(
      `https://api.sociana.in/api/v1/oc/146/posts`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      ).then(res =>{
        if (res.status == 200 || res.status == 201) {
          return (
              setSnackbarData({
                  message: 'Post created Successfully.',
                  type: 'success'
              }),
              res.json()
          );
      }}
      ).then(result=> {
        if(result){
          setSaveButtonLoading(false)
          setCreateData({Pictures:null,Video:null,Caption:""})
          setPostData({
            data: [result?.data,...postData?.data],
            included: [...result?.included,...postData?.included],
          });
          window.location.reload(false)
          handlePostFloatingButtonClose()
        }
    })
  }}catch{
    setSnackbarData({
        message: 'Something Went Wrong.',
        type: 'error'
    });
   }
  }

  console.log("postdataaa",postData.data)
  console.log("postincccc",postData.included)
  return (
    <>
      <Grid
        container
        style={{ overscrollBehaviorX: "hidden"}}
      >
        <Grid style={{ width: "100%", background: "#221c46", padding: "20px 25px",display:"flex",justifyContent:"center" }}>
                    <Grid style={{ width: "400px" }}>
                    <img style={{width:"100%"}} src={TIMELINELOGO} alt="Logo" />
                    </Grid>
                </Grid>
        <Grid
          style={{
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <Grid
            style={{
              width: "100%",
              padding: "20px 20px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Button
              onClick={onclicked}
              style={{
                padding: "10px 10px",
                textTransform: "none",
                background: "none",
                color: "#ffffff",
                fontSize: "25px",
                fontWeight: "500",
                fontFamily: "'Roboto Condensed', sans-serif",
              }}
            >
              <img style={{ marginRight: "10px" }} src={LEFT_ARROW} alt="<-" />
              <b style={{ color: "black" }}>Timeline</b>
            </Button>
          </Grid>
          <Grid>
          {postData?.data?.length > 0 ? (
            <>
            <Grid style={{marginBottom:"80px",textAlign:"center"}}>
              {postData?.data?.map((item) => (
                <PostCard 
                  id={item?.id}
                  userName={
                    postData?.included?.find(
                      (el) => el?.id == item?.relationships?.user?.data?.id && el?.type == item?.relationships?.user?.data?.type
                    )?.attributes?.name
                  }
                  userProfilePic={
                    postData?.included?.find(
                      (el) => el?.id == item?.relationships?.user?.data?.id && el?.type == item?.relationships?.user?.data?.type
                    )?.attributes?.avatarUrl
                  }
                  hashTag={item?.attributes?.hashTag}
                  message={item?.attributes?.message}
                  isLike={item?.attributes?.isLike}
                  likeCount={item?.attributes?.likeCount}
                  pictureUrl={item?.attributes?.pictureUrl}
                  videoThumbnailUrls={item?.attributes?.videoThumbnailUrls}
                  videoUrls={item?.attributes?.videoUrls}
                  totalComment={item?.attributes?.totalComment}
                  createdAt={item?.attributes?.createdAt}
                  token={token}
                />
              ))}
              {loadMore}
              </Grid>
            </>
          ) : (
            <EmptyData color="#000000" />
          )}
          <Box className="floating_box" sx={{ '& > :not(style)': { m: 1} }}>
                <Fab className="floating_btn" aria-label="add" onClick={handlePostFloatingButtonOpen}>
                  <b style={{fontSize:"25px"}}>+</b>
                </Fab>
          </Box>
          </Grid>
          {/* <Grid style={{width:"100%",margin:"60px 0",position:"fixed",bottom:"0",left:"0",display:"flex",justifyContent:"center",alignItems:"center"}}>
          <Grid style={{width:"100%",maxWidth:"400px",textAlign:"end",zIndex:"-1"}}> */}
          {/* <Box sx={{ '& > :not(style)': { m: 1} }}>
                <Fab style={{background:"#221c46",color:"white",border:"0.5px solid white"}} aria-label="add" onClick={handlePostFloatingButtonOpen}>
                  <b style={{fontSize:"25px"}}>+</b>
                </Fab>
              </Box> */}
          {/* </Grid>
          </Grid> */}
        </Grid>
      </Grid>
      <BootstrapDialog
          onClose={handlePostFloatingButtonClose}
          aria-labelledby="customized-dialog-title"
          open={editOpen}
        >
      <DialogActions
            style={{
              minWidth:"100%",
              padding:"25px 15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding:"0 0"
            }}
          >
            <Grid style={{width:"100%", display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
              <Grid style={{width:"100%",background:"#221C46",padding:"10px 5px",textAlign:"center"}}>
                <Typography variant="h6" style={{color:"white",fontSize: "20px",
                fontWeight: "500",
                fontFamily: "'Roboto Condensed', sans-serif"}}>
                Create Post
                </Typography>
              </Grid>
            <Grid style={{width:"100%", display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start",margin:"20px 0",padding:"0 20px"}}>
            {!createData.Video && <label style={{fontSize:"18px",margin:"8px 0 12px 0",fontFamily: "'Roboto Condensed', sans-serif",color:"#221C46",fontWeight:"600"}}>Upload Pictures</label>}
            {!createData.Video && <input
            style={{width:"100%",marginBottom:"15px"}}
              accept="image/*"
              id="raised-button-file"
              placeholder="Select Image"
              type="file"
              multiple
              name="Pictures"
              onChange={handleChange}
            />}
            {!createData.Pictures && !createData.Video ? <Grid style={{width:"100%",textAlign:"center",marginTop:"12px"}}>
            <Typography variant="subtitle" style={{fontWeight:"600"}}>or</Typography>
            </Grid> : <></>}
            {!createData.Pictures && <label style={{fontSize:"18px",margin:"8px 0 12px 0",fontFamily: "'Roboto Condensed', sans-serif",color:"#221C46",fontWeight:"600"}}>Upload Video</label>}
            {!createData.Pictures && <input
            style={{width:"100%"}}
              accept="video/mp4"
              id="raised-button-file"
              placeholder="Select Video"
              type="file"
              name="Video"
              onChange={handleChange}
            />}
            {errorMessages.Media && <Grid><Typography style={{color:"red",marginTop:"5px 0 15px 0"}}>{errorMessages.Media}</Typography></Grid>}
            </Grid>
            <Grid style={{width:"100%",height:"8px",background:"#D9D9D9",marginBottom:"20px"}}></Grid>
            <Grid style={{width:"100%", display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start",marginBottom:"10px",padding:"0 20px"}}>
            <textarea
            style={{width:"100%",height:"60px",outline:"none",padding:"10px 15px",overflowY:"scroll",resize:"none",overflow:"auto",border:"2px solid #221C46",fontFamily: "'Roboto Condensed', sans-serif",fontWeight:"600"}}
              id="raised-button-file"
              placeholder="Write Caption here"
              type="text"
              onChange={handleChange}
              name="Caption"
              value={createData.Caption}
            />
            {errorMessages.Caption && <Typography style={{color:"red"}}>{errorMessages.Caption}</Typography>}
            </Grid>
            {saveButtonLoading ? <Button style={{width:"70%",background:"#00EB5E",color:"#221C46",padding:"6px 2px",marginBottom:"20px",fontFamily: "'Roboto Condensed', sans-serif",color:"#221C46",fontWeight:"700",fontSize:"18px",textTransform:"none"}} disabled>
              Loading... 
            </Button> :
            <Button style={{width:"70%",background:"#00EB5E",padding:"6px 2px",marginBottom:"20px",fontFamily: "'Roboto Condensed', sans-serif",color:"#221C46",fontWeight:"700",fontSize:"18px",textTransform:"none"}} onClick={handleCreatePost}>
              Post now
            </Button>}
            </Grid>
          </DialogActions>
          </BootstrapDialog>

      <Snackbar
        open={!!snackbarData.message}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        style={{ border: "2px solid black", borderRadius: 5 }}
        message={snackbarData.message}
      >
        <Alert severity={snackbarData.type}>{snackbarData.message}</Alert>
      </Snackbar>
    </>
  );
};

export default Timeline;
