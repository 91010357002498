import { Grid, Typography } from '@mui/material';
import React from 'react';
import GreenIcon from "../Assets/GreenRectIcon.png";

const FaqsBtnDetails = ({faqsData}) => {
  return (
    <Grid style={{width:"100%",padding:"20px 0"}}>
        {
            faqsData && faqsData.map((item,id)=>{
                return <>
                <Grid style={{width:"100%",padding:"10px 15px",background:"#221C46",display:"flex",justifyContent:"flex-start",alignItems:"flex-start"}}>
                <Grid className='btn_item_left'><img src={GreenIcon} alt='GreenIcon'/></Grid>
                <Typography style={{color:"#ffffff",fontSize: "22px", fontWeight: "600", fontFamily: "'Roboto Condensed', sans-serif",lineHeight:"25px" }}>{item.ques}</Typography>
                </Grid>
                {item.ans ?  <Grid key={id} style={{width:"100%",display:"flex",justifyContent:"flex-start",alignItems:"flex-start",padding:"15px 25px 15px 25px", background:"#ffffff"}}>
                <>
                 <Grid className='btn_item_right'>{item.ans}</Grid> </> 
                </Grid> : <></>}

                {item.ansopt ? <Grid style={{width:"100%",display:"flex",justifyContent:"flex-start",alignItems:"flex-start",padding:"15px 25px 15px 25px", background:"#ffffff"}}>
                 <>
                 <Grid style={{display:"block",padding:"0 25px 15px 10px"}}>
                 {item.ansopt.map(el=>{
                    return <Typography style={{width:"100%",color:"#221C46",fontFamily: "'Roboto Condensed', sans-serif",fontWeight:"400px",fontSize:"18px",lineHeight:"22px"}}>{el}</Typography>
                     })} </Grid> </> 
                </Grid>: <></>}
                
            
            </>
            })
        }          
    </Grid>
  )
}

export default FaqsBtnDetails;
