import "../ComponentStyles/footer.css";
import { Grid, Typography } from '@mui/material';
import React from 'react';
import FHOMEIMG from "../Assets/FHOMEICON.png";
import FAGENDAIMG from "../Assets/FAGENDAICON.png";
import FTIMELINEIMG from "../Assets/FTIMELINEICON.png";
import FNOTIFICATIONIMG from "../Assets/FNOTIFICATIONICON.png";
import FPROFILEIMG from "../Assets/FPROFILEICON.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <Grid container style={{width:"100%",display:"grid",placeItems:"center",marginTop:"auto",position:"fixed",height:"60px",bottom:"0"}}>
        <div className='footer_rout_container'>
                    <NavLink className="footnavbar" to="/"><img src={FHOMEIMG} alt='TQUIZIMG'/><Typography variant='button' className="footer_topogpy" style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"10px"}}>Home</Typography></NavLink>

                    <NavLink className="footnavbar" to="/agenda"><img src={FAGENDAIMG} alt='POLLSIMG'/><Typography variant='button' className="footer_topogpy" style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"10px"}}>Agenda</Typography></NavLink>

                    <NavLink className="footnavbar" to="/timeline"><img src={FTIMELINEIMG} alt='CONTESTSIMG'/><Typography variant='button' className="footer_topogpy" style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"10px"}}>Timeline</Typography></NavLink>

                    <NavLink className="footnavbar" to="/notification"><img src={FNOTIFICATIONIMG} alt='HIGHLIGHTSIMG'/><Typography variant='button' className="footer_topogpy" style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"10px",marginTop:"6px"}}>Notification</Typography></NavLink>

                    <NavLink className="footnavbar" to="/profile"><img src={FPROFILEIMG} alt='LEADERBOARDIMG'/><Typography variant='button' className="footer_topogpy" style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"10px"}}>Profile</Typography></NavLink>
         </div>
    </Grid>
  )
}

export default Footer;
