import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { red } from "@mui/material/colors";
import { Grid } from "@mui/material";
import ReadMoreReadLess from "./ReadMoreReadLess";
import LIKEIMG from "../Assets/TimeLine/like_img.png";
import LIKEDIMG from "../Assets/LikedIcon.png";
import COMMENTIMG from "../Assets/TimeLine/comment_img.png";
import VARTICALTHREEDOTIMG from "../Assets/TimeLine/vartical_three_dot_img.png";
import CarouselView from "./Carousel";
import CommentCard from "../Components/CommentCard";



const PostCard = ({
  id,
  pictureUrl = [],
  hashTag = "",
  message = "",
  isLike = "",
  likeCount = 0,
  totalComment = 0,
  videoUrls = [],
  videoThumbnailUrls = [],
  createdAt = "",
  userName = "",
  userProfilePic = "",
  token
}) => {
  
  const [Like,setLike] = useState({isLike:isLike,likeCount:likeCount,totalComment:totalComment});
  const [editOpen, setEditOpen] = React.useState(false);
 
  const handleLike = (iid)=>{
    try{
      const formData = new FormData();
      formData.append("like[likeable_id]",iid);
      formData.append("like[likeable_type]","Post");
      fetch(
        `https://api.sociana.in/api/v1/oc/146/likes/toggle`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        ).then(res =>{
          if (res.status == 200 || res.status == 201) {
            return (
                res.json()
            );
        }}
        ).then(result=> {
          console.log("resultresult",result)
          if(result){
            let PostObj = result.included.find(el=>
              el.type == "posts"
            );
            console.log("postobj",PostObj)
            setLike({...Like,
              isLike:PostObj.attributes.isLike,
              likeCount:PostObj.attributes.likeCount,
            })
          }
      })
    }catch{
      console.log("error")
    }
  };


 
  const handleCommentClose = () => {
    setEditOpen(false);
  };
  const handleComments = ()=>{
    setEditOpen(true);
  }

  // console.log("likeeeee",Like)

  return (
    <>
    <Grid
      container
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "10px 0 20px 0",
        padding: "0 10px",
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          width: 400,
          minHeight: 400,
          boxShadow: "0 0 2px 0.5px gray",
          backgroundColor: "whitesmoke",
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: red[500], width: "50px", height: "50px" }}
              aria-label="recipe"
            >
              {userProfilePic ? (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={`https://api.sociana.in//${userProfilePic}`}
                  alt="Logo"
                />
              ) : (
                userName.substring(0, 1)
              )}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              {" "}
              <img src={VARTICALTHREEDOTIMG} alt="Dot" />
            </IconButton>
          }
          title={<b>{userName}</b>}
          subheader={new Date(createdAt).toDateString()}
        />
        <CarouselView
          pictureUrl={pictureUrl}
          videoUrl={videoUrls}
          videoThumbnailUrls={videoThumbnailUrls}
        />
        <CardContent style={{ padding: "10px 10px 5px 10px" }}>
          <Typography variant="body2" color="text.secondary">
            {hashTag}
          </Typography>
        </CardContent>
        <CardContent style={{ padding: "5px 10px 5px 10px" }}>
          <Typography paragraph style={{ fontSize: "13px", marginBottom: "0" }}>
            {message && (
              <ReadMoreReadLess limit="100">{message}</ReadMoreReadLess>
            )}
          </Typography>
        </CardContent>

        <CardActions disableSpacing>
          {Like.isLike ? <IconButton aria-label="add to favorites" onClick={()=> handleLike(id)}>
            <img src={LIKEDIMG} alt="DisLike" />
            <span style={{ fontSize: "13px", marginLeft: "5px",cursor:"pointer" }}>
              {Like.likeCount} Likes
            </span>
          </IconButton> :<IconButton aria-label="add to favorites" onClick={()=> handleLike(id)}>
            <img src={LIKEIMG} alt="Like" />
            <span style={{ fontSize: "13px", marginLeft: "5px",cursor:"pointer" }}>
              {Like.likeCount} Likes
            </span>
          </IconButton>}
          <IconButton aria-label="share" onClick={() =>handleComments()}>
            <img src={COMMENTIMG} alt="Comment" />
            <span style={{ fontSize: "13px", marginLeft: "5px" }}>
              {Like.totalComment} Comment
            </span>
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
    {editOpen && <CommentCard cardId={id} totalComment={totalComment} handleCommentClose={handleCommentClose} editOpen={editOpen} token={token} setLike={setLike} Like={Like}/>}
    </>
  );
};

export default PostCard;