import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Snackbar,
  Alert,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

import TIMELINELOGO from "../../Assets/homelogo.jpeg";
import LEFT_ARROW from "../../Assets/AgendaIcon.png";
import EmptyData from "../../Components/EmptyData";
import PostCard from "../../Components/PostCard";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Contests = () => {
  let token = localStorage.getItem("token");
  const [page, setPage] = React.useState(1);
  const [ContestData, setContestData] = React.useState({
    data: [],

    links: {},
  });
  const [snackbarData, setSnackbarData] = React.useState({
    message: "",
    type: "success",
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    try {
      fetch(
        `https://api.sociana.in/api/v2/oc/146/visitant/competition/competitions?page=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            return (
              setSnackbarData({
                message: "All competitions loaded successfully.",
                type: "success",
              }),
              res.json()
            );
          }
          return res.json();
        })
        .then((results) => {
          if (results) {
            setContestData({
              data: ContestData?.data.concat(results?.data),
              links: results?.links,
            });
          }
        });
    } catch (error) {
      setSnackbarData({
        message: "Something Went Wrong.",
        type: "error",
      });
      console.error(error);
    }
  }, [page]);

  const handleClose = () => {
    setSnackbarData({
      message: "",
      type: "success",
    });
  };

  const onclicked = () => {
    navigate("/");
  };

  const getNextPage = (str) => {
    const str1 = str.split("=");
    const res = str1[1].split("&");
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (!!ContestData?.links?.next) {
      const pageNumber = getNextPage(ContestData?.links?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = React.useMemo(() => {
    return !!ContestData?.links?.next ? (
      <Grid textAlign="center" style={{ width: "100%" }}>
        <Button
          style={{
            marginBottom: "20px",
            cursor: "pointer",
            padding: "5px 20px",
            backgroundColor: "#221c46",
            color: "white",
            borderRadius: "10px",
          }}
          onClick={onLoadMore}
        >
          loading more
        </Button>
      </Grid>
    ) : null;
  }, [ContestData?.links?.next]);

  return (
    <>
      <Grid
        container
        style={{ overscrollBehaviorX: "hidden"}}
      >
        <Grid style={{ width: "100%", background: "#221c46", padding: "20px 25px",display:"flex",justifyContent:"center" }}>
                    <Grid style={{ width: "400px" }}>
                    <img style={{width:"100%"}} src={TIMELINELOGO} alt="Logo" />
                    </Grid>
                </Grid>
        <Grid
          style={{
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <Grid
            style={{
              width: "100%",
              padding: "20px 20px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Button onClick={onclicked} style={{ padding: "10px 10px", textTransform: "none", background: "none", color: "#ffffff", fontSize: "25px", fontWeight: "500", fontFamily: "'Roboto Condensed', sans-serif" }}><img style={{ marginRight: "10px" }} src={LEFT_ARROW} alt='<-' /><b style={{color:"#221C46"}}>Contests</b>
            </Button>
          </Grid>
          {ContestData?.data?.length > 0 ? (
            <>
            <Grid style={{marginBottom:"80px"}}>
              {ContestData?.data?.map((item) => (
                <Grid style={{ width: "100%", padding: "20px 20px" }}>
                  <Grid
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Card
                      sx={{
                        width: "400px",
                        margin: "15px 0",
                        padding: "5px 5px 0 5px",
                        borderRadius: "10px",
                      }}
                    >
                      {item?.attributes?.cardImageUrl && (
                        <Zoom>
                          <Grid style={{height:"auto",display:"flex",justifyContent:"center"}}>
                          <img
                          style={{ width:"100%",height:"100%", margin: "10px 5px 0 5px" }}
                          src={`https://api.sociana.in//${item?.attributes?.cardImageUrl}`}
                          alt="contestImg"
                        />
                          </Grid>
                        </Zoom>
                      )}
                      <CardContent>
                        {item?.attributes?.name && (
                          <Typography gutterBottom variant="h5">
                            {item.attributes.name}
                          </Typography>
                        )}
                        {item?.attributes?.startTime ||
                        item?.attributes?.endTime ? (
                          <Typography
                            variant="body2"
                            style={{
                              marginBottom: "5px",
                              backgroundColor: "#221c46",
                              color: "white",
                              padding: "5px 5px",
                              borderRadius: "8px",
                              fontSize: "13px",
                            }}
                          >
                            {`${new Date(
                              item?.attributes?.startTime
                            ).toDateString()} - ${new Date(
                              item?.attributes?.endTime
                            ).toDateString()}`}
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item?.attributes?.rules && (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            style={{ marginBottom: "10px" }}
                          >
                            {item?.attributes?.rules}
                          </Typography>
                        )}
                        {item?.attributes?.description && (
                          <Typography variant="body2" color="text.secondary">
                            {item?.attributes?.description}
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ))}
              {loadMore}
              </Grid>
            </>
          ) : (
            <EmptyData color="#000000" />
          )}
        </Grid>
      </Grid>

      <Snackbar
        open={!!snackbarData.message}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        style={{ border: "2px solid black", borderRadius: 5 }}
        message={snackbarData.message}
      >
        <Alert severity={snackbarData.type}>{snackbarData.message}</Alert>
      </Snackbar>
    </>
  );
};

export default Contests;
