import HomeVideo from "../Assets/videos/HomeVideo.mp4";
import "../ComponentStyles/video.css";

export const Video = () => {
    return (
        <video className="homevideo" controls>
            <source src={HomeVideo} type="video/mp4" />
            Sorry, your browser doesn't support videos.
        </video>
    )
}