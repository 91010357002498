import {
  AddPhotoAlternate,
  Fullscreen,
  Image,
  KeyboardBackspace,
  SwapHoriz,
  VideoCall,
  Videocam,
  DriveFileMoveOutlined,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import InfiniteScroll from "react-infinite-scroll-component";
import HighlightLogo from "../../Assets/homelogo.jpeg";
import LEFTARROW from "../../Assets/AgendaIcon.png";
import ActiveImageIcon from "../../Assets/ActiveImageIcon.png";
import ActiveVideoIcon from "../../Assets/ActiveVideoIcon.png";
import ImageIcon from "../../Assets/ImageIcon.png";
import VideoIcon from "../../Assets/VideoIcon.png";
import HighlightModel from "../../Components/HighlightModel";

import styles from "./highlights.module.css";
import { useNavigate } from "react-router-dom";
import HighlightAlbum from "../../Components/HighlightAlbum";
import Checkbox from "@mui/material/Checkbox";
import { ErrorBoundary } from "react-error-boundary";
const OCCASION_ID = "146";
const GALLERY_ID = "45";
const IMAGE_URL = "https://api.sociana.in";

const Highlights = () => {
  let token = localStorage.getItem("token");
  let currentUserId = localStorage.getItem("currentUserId");
  const [mediableType, setMediableType] = useState("Gallary");
  const [displayImageData, setDisplayImageData] = useState({
    data: [],
    included: [],
    links: {},
  });
  const [displayVideoData, setDisplayVideoData] = useState({
    data: [],
    included: [],
    links: {},
  });
  const [isImage, setIsImage] = useState(1);
  const [albums, setAlbums] = useState({ data: [], link: {} });
  const [isNextPagePresent, setIsNextPagePresent] = useState(false);
  const [page, setPage] = useState(1);
  const [albumPage, setAlbumPage] = useState(1);
  const [isMediaModelOpen, seIsMedianModelOpen] = React.useState(false);
  const [fileType, setFileType] = useState("");
  const [isAlbumOpen, setIsAlbumOpen] = useState(false);
  const [albumId, setAlbumId] = useState();
  const [isDeleteCheckBox, setIsDeleteCheckBox] = useState(false);
  const [mediaIds, setMediaIds] = useState([]);

  const navigate = useNavigate();

  const onclicked = () => {
    navigate("/");
  };

  useEffect(() => {
    fetch(
      `https://api.sociana.in/api/v2/oc/${OCCASION_ID}/visitant/gallary/albums?page=${albumPage}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result?.data) {
          const { data, links } = result;
          const newData = data.map((item) => {
            return { ...item.attributes, id: item.id };
          });
          setAlbums({
            data: albums?.data?.concat(newData),
            link: { ...links },
          });
        }
      });
  }, [albumPage]);

  useEffect(() => {
    handleFetch(page, isImage);
  }, [mediableType, isImage, page]);

  const handleFetch = (page, isImage) => {
    if (isImage == 1) {
      fetch(
        `https://api.sociana.in/api/v2/oc/${OCCASION_ID}/visitant/gallary/get_image_media?page=${page}&mediable_type=${mediableType}&mediable_id=${GALLERY_ID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result?.data?.length) {
            const { data, included, links } = result;
            // setIsNextPagePresent(!!result?.links?.next);
            let NewData = displayImageData?.data
              ?.concat(data)
              .filter(
                (obj, index, array) =>
                  array.findIndex((el) => el.id === obj.id) === index
              );
            let NewIncluded = included;
            let NewLinks = links;

            if (page !== 1) {
              setDisplayImageData((pre) => ({
                data: [...NewData],
                included: [...pre?.included, ...NewIncluded],
                links: NewLinks,
              }));
            } else {
              setDisplayImageData({
                data: [...NewData],
                included: [...NewIncluded],
                links: NewLinks,
              });
            }
          }
        });
    }
    if (isImage == 2) {
      fetch(
        `https://api.sociana.in/api/v2/oc/${OCCASION_ID}/visitant/gallary/get_video_media?page=${page}&mediable_type=${mediableType}&mediable_id=${GALLERY_ID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result?.data?.length) {
            const { data, included, links } = result;
            // setIsNextPagePresent(!!result?.links?.next);
            let NewData = displayVideoData?.data
              ?.concat(data)
              .filter(
                (obj, index, array) =>
                  array.findIndex((el) => el.id === obj.id) === index
              );
            let NewIncluded = included;
            let NewLinks = links;

            if (page !== 1) {
              setDisplayVideoData((pre) => ({
                data: [...NewData],
                included: [...pre?.included, ...NewIncluded],
                links: NewLinks,
              }));
            } else {
              setDisplayVideoData({
                data: [...NewData],
                included: [...NewIncluded],
                links: NewLinks,
              });
            }
          }
        });
    }
  };

  const getNextPageForAlbums = (str) => {
    const str1 = str.split("=");
    const res = str1[1].split("&");
    return parseInt(res[0], 10);
  };

  const onLoadMoreForAlbums = () => {
    if (!!albums?.link?.next) {
      const pageNumber = getNextPageForAlbums(albums?.link?.next);
      setAlbumPage(pageNumber);
    }
  };

  const loadMoreForAlubms = React.useMemo(() => {
    return !!albums?.link?.next ? (
      <Grid textAlign="center" style={{ width: "100%" }}>
        <Button
          style={{
            cursor: "pointer",
            padding: "5px 10px",
            backgroundColor: "#221c46",
            color: "white",
            borderRadius: "25px",
            fontFamily: '"Roboto Condensed", sans-serif',
          }}
          onClick={onLoadMoreForAlbums}
        >
          More <KeyboardDoubleArrowRight style={{ marginLeft: 5 }} />
        </Button>
      </Grid>
    ) : null;
  }, [albums?.link?.next]);

  const getNextPageForImage = (str) => {
    const str1 = str?.split("=");

    const res = str1[3]?.split("&");

    return parseInt(res[0], 10);
  };

  const onLoadMoreForImage = () => {
    if (!!displayImageData?.links?.next) {
      const pageNumber = getNextPageForImage(displayImageData?.links?.next);
      setPage(pageNumber);
    }
  };

  const loadMoreForImage = React.useMemo(() => {
    return !!displayImageData?.links?.next ? (
      <Grid textAlign="center" style={{ width: "100%" }}>
        <Button
          style={{
            marginBottom: "20px",
            cursor: "pointer",
            padding: "5px 20px",
            backgroundColor: "#221c46",
            color: "white",
            borderRadius: "10px",
            fontFamily: '"Roboto Condensed", sans-serif',
          }}
          onClick={onLoadMoreForImage}
        >
          load more
        </Button>
      </Grid>
    ) : null;
  }, [displayImageData?.links?.next]);

  const getNextPageForVideo = (str) => {
    const str1 = str.split("=");
    const res = str1[3].split("&");
    return parseInt(res[0], 10);
  };

  const onLoadMoreForVideo = () => {
    if (!!displayVideoData?.links?.next) {
      const pageNumber = getNextPageForVideo(displayVideoData?.links?.next);
      setPage(pageNumber);
    }
  };

  const loadMoreForVideo = React.useMemo(() => {
    return !!displayVideoData?.links?.next ? (
      <Grid textAlign="center" style={{ width: "100%" }}>
        <Button
          style={{
            marginBottom: "20px",
            cursor: "pointer",
            padding: "5px 20px",
            backgroundColor: "#221c46",
            color: "white",
            borderRadius: "10px",
            fontFamily: '"Roboto Condensed", sans-serif',
          }}
          onClick={onLoadMoreForVideo}
        >
          loading more
        </Button>
      </Grid>
    ) : null;
  }, [displayVideoData?.links?.next]);

  const handleMediaModelOpen = (type) => {
    seIsMedianModelOpen(true);
    setFileType(type);
  };

  const handleMediaModelClose = () => {
    seIsMedianModelOpen(false);
  };

  const handleAlbum = (id) => {
    setAlbumId(id);
    setIsAlbumOpen(true);
  };

  const handleMultipleCheckBox = () => {
    setIsDeleteCheckBox(true);
  };
  const handleCancelDelete = () => {
    setIsDeleteCheckBox(false);
    setMediaIds([]);
  };
  const handleDelete = () => {
    try {
      let formdata = new FormData();
      if (mediaIds.length > 0) {
        mediaIds.forEach((item) => {
          formdata.append("media_ids[]", item);
        });
      }
      fetch(
        "https://api.sociana.in/api/v2/oc/146/visitant/gallary/multiple_destroy?mediable_type=Gallary&mediable_id=45",
        {
          method: "DELETE",
          body: formdata,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((res) =>
        res.json().then((result) => {
          if (result?.data) {
            console.log("result?.data", result?.data);
            if (isImage === 1) {
              let prevData = displayImageData?.data;

              let arrayOfDeletedDataIds = result?.data?.map((el) => {
                return el.id;
              });
              let filterData = prevData.filter(
                (item) => !arrayOfDeletedDataIds.includes(item.id)
              );

              setDisplayImageData({
                ...displayImageData,
                data: [...filterData],
              });
            }
            if (isImage === 2) {
              let prevData = displayVideoData?.data;

              let arrayOfDeletedDataIds = result?.data?.map((el) => {
                return el.id;
              });
              let filterData = prevData.filter(
                (item) => !arrayOfDeletedDataIds.includes(item.id)
              );

              setDisplayVideoData({
                ...displayVideoData,
                data: [...filterData],
              });
            }
          }
        })
      );
    } catch {
      console.log("error");
    }
    setIsDeleteCheckBox(false);
  };

  const handleCheckboxId = (e) => {
    if (e.target.checked) {
      setMediaIds((pre) => [...pre, e.target.value]);
    } else {
      setMediaIds((pre) => pre.filter((id) => id != e.target.value));
    }
  };

  function ErrorHandler({ error }) {
    return (
      <div role="alert">
        <p>An error occurred:</p>
        {/* <pre>{error.message}</pre> */}
      </div>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <Grid container style={{ marginBottom: "60px" }}>
        <Grid
          style={{
            width: "100%",
            background: "#221c46",
            padding: "20px 25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid style={{ width: "400px" }}>
            <img style={{ width: "100%" }} src={HighlightLogo} alt="Logo" />
          </Grid>
        </Grid>
        <Grid
          style={{
            width: "100%",
            padding: "20px 20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Button
            onClick={onclicked}
            style={{
              padding: "10px 10px",
              textTransform: "none",
              background: "none",
              color: "#ffffff",
              fontSize: "25px",
              fontWeight: "500",
              fontFamily: "'Roboto Condensed', sans-serif",
            }}
          >
            <img
              style={{ marginRight: "10px" }}
              src={LEFTARROW}
              alt="left-arrow"
            />
            <b style={{ color: "#221C46" }}>Highlight</b>
          </Button>
        </Grid>
        {!isAlbumOpen ? (
          <Grid
            container
            item
            direction="column"
            wrap="nowrap"
            style={{
              width: "100%",
              maxWidth: 500,
              overflow: "hidden",
              marginLeft: "auto",
              marginRight: "auto",
              minHeight: "100vh",
              background: "#ffffff",
              fontSize: "22px",
              marginBottom: 80,
              padding: "20px 0px",
            }}
          >
            <Grid
              container
              item
              justifyContent="space-around"
              style={{ margin: "20px 0px", padding: "0px 15px" }}
            >
              <Button
                className={styles.addButton}
                onClick={() => handleMediaModelOpen("image")}
              >
                <AddPhotoAlternate /> Add Photo
              </Button>
              <Button
                className={styles.addButton}
                onClick={() => handleMediaModelOpen("video")}
              >
                <VideoCall /> Add Video
              </Button>
            </Grid>
            <Grid style={{ margin: "5px 0px 20px 0", padding: "0 15px" }}>
              <Typography
                style={{
                  color: "#221c46",
                  fontFamily: '"Roboto Condensed", sans-serif',
                  fontSize: "24px",
                  fontWeight: "500",
                }}
              >
                Albums
              </Typography>
              <Grid container item wrap="nowrap" style={{ overflowX: "auto" }}>
                {albums?.data?.map((item, index) => (
                  <Button
                    key={index}
                    style={{ margin: "0px 10px" }}
                    className={styles.addButton}
                    onClick={() => handleAlbum(item.id)}
                  >
                    <DriveFileMoveOutlined style={{ marginRight: 5 }} />
                    {item.title}
                  </Button>
                ))}
                {albums?.data?.length > 0 ? loadMoreForAlubms : null}
              </Grid>
              <SwapHoriz />
            </Grid>
            <Grid
              container
              item
              style={{
                margin: "20px 0px",
                background: "#221c46",
                padding: "2px 5px",
              }}
            >
              {isImage === 1 ? (
                <Button
                  onClick={() => {
                    setIsImage(1);
                    setIsDeleteCheckBox(false);
                    setMediaIds([]);
                  }}
                  style={{ flex: 1, background: "#ffffff", padding: "8px 0" }}
                >
                  <img src={ImageIcon} alt="ImageIcon" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setIsImage(1);
                    setIsDeleteCheckBox(false);
                    setMediaIds([]);
                  }}
                  style={{ flex: 1, padding: "8px 0" }}
                >
                  <img src={ActiveImageIcon} alt="ActiveImageIcon" />
                </Button>
              )}
              {isImage === 2 ? (
                <Button
                  onClick={() => {
                    setIsImage(2);
                    setIsDeleteCheckBox(false);
                    setMediaIds([]);
                  }}
                  style={{ flex: 1, background: "#ffffff", padding: "8px 0" }}
                >
                  <img src={VideoIcon} alt="VideoIcon" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setIsImage(2);
                    setIsDeleteCheckBox(false);
                    setMediaIds([]);
                  }}
                  style={{ flex: 1, padding: "8px 0" }}
                >
                  <img src={ActiveVideoIcon} alt="ActiveVideoIcon" />
                </Button>
              )}
            </Grid>

            {displayImageData?.data?.filter(
              (item) =>
                item?.relationships?.createdBy?.data?.id == currentUserId
            )?.length > 0 &&
            displayImageData?.data?.length > 0 &&
            isImage == 1 ? (
              isDeleteCheckBox ? (
                <Grid
                  style={{
                    width: "100%",
                    padding: "0px 10px",
                    textAlign: "end",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid style={{ margin: "5px 10px" }}>
                    <Typography
                      style={{
                        color: "#221c46",
                        fontFamily: '"Roboto Condensed", sans-serif',
                        fontWeight: "700",
                      }}
                    >
                      You are only able to delete photos that you have uploaded.
                    </Typography>
                  </Grid>
                  <Grid style={{ margin: "5px 10px" }}>
                    <Button
                      style={{
                        cursor: "pointer",
                        padding: "5px 10px",
                        backgroundColor: "#221c46",
                        color: "white",
                        borderRadius: "10px",
                        marginBottom: "5px",
                        marginRight: "10px",
                        fontFamily: '"Roboto Condensed", sans-serif',
                      }}
                      onClick={handleCancelDelete}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        cursor: "pointer",
                        padding: "5px 10px",
                        backgroundColor: "#FF7375",
                        color: "#221C46",
                        borderRadius: "10px",
                        marginBottom: "5px",
                        fontWeight: "700",
                        fontFamily: '"Roboto Condensed", sans-serif',
                      }}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  style={{
                    width: "100%",
                    padding: "0px 10px",
                    textAlign: "end",
                  }}
                >
                  <Button
                    style={{
                      cursor: "pointer",
                      padding: "5px 10px",
                      backgroundColor: "#221c46",
                      color: "white",
                      borderRadius: "10px",
                      marginBottom: "5px",
                      fontFamily: '"Roboto Condensed", sans-serif',
                    }}
                    onClick={handleMultipleCheckBox}
                  >
                    Multiple Delete
                  </Button>
                </Grid>
              )
            ) : null}
            {displayVideoData?.data?.filter(
              (item) =>
                item?.relationships?.createdBy?.data?.id == currentUserId
            )?.length > 0 &&
            displayVideoData?.data?.length > 0 &&
            isImage == 2 ? (
              isDeleteCheckBox ? (
                <Grid
                  style={{
                    width: "100%",
                    padding: "0 10px",
                    textAlign: "end",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid style={{ margin: "5px 10px" }}>
                    <Typography
                      style={{
                        color: "#221c46",
                        fontFamily: '"Roboto Condensed", sans-serif',
                        fontWeight: "700",
                      }}
                    >
                      You are only able to delete videos that you have uploaded.
                    </Typography>
                  </Grid>
                  <Grid style={{ margin: "5px 10px" }}>
                    <Button
                      style={{
                        cursor: "pointer",
                        padding: "5px 10px",
                        backgroundColor: "#221c46",
                        color: "white",
                        borderRadius: "10px",
                        marginBottom: "5px",
                        marginRight: "10px",
                        fontFamily: '"Roboto Condensed", sans-serif',
                      }}
                      onClick={handleCancelDelete}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        cursor: "pointer",
                        padding: "5px 10px",
                        backgroundColor: "#FF7375",
                        color: "#221C46",
                        borderRadius: "10px",
                        marginBottom: "5px",
                        fontWeight: "700",
                        fontFamily: '"Roboto Condensed", sans-serif',
                      }}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  style={{
                    width: "100%",
                    padding: "0px 10px",
                    textAlign: "end",
                  }}
                >
                  <Button
                    style={{
                      cursor: "pointer",
                      padding: "5px 10px",
                      backgroundColor: "#221c46",
                      color: "white",
                      borderRadius: "10px",
                      marginBottom: "5px",
                      fontFamily: '"Roboto Condensed", sans-serif',
                    }}
                    onClick={handleMultipleCheckBox}
                  >
                    Multiple Delete
                  </Button>
                </Grid>
              )
            ) : null}

            {isImage == 1 && (
              <Grid style={{ padding: "0 15px" }}>
                {displayImageData?.data?.length > 0 ? (
                  <>
                    <ImageList
                      sx={{ marginBottom: 3 }}
                      cols={3}
                      rowHeight={160}
                    >
                      {displayImageData?.data.map((item) => {
                        return (
                          <Zoom key={item.id}>
                            <ImageListItem
                              key={item.id}
                              cols={1}
                              rows={1}
                              style={{ position: "relative" }}
                            >
                              <img
                                src={`${IMAGE_URL}/${item.attributes.thumbUrl}`}
                                style={{
                                  objectFit: "cover",
                                  height: 150,
                                  width: 150,
                                  marginBottom: 5,
                                }}
                                alt={item.attributes.name}
                              />
                              {isDeleteCheckBox &&
                              item?.relationships?.createdBy?.data?.id ==
                                currentUserId ? (
                                <Checkbox
                                  value={item.id}
                                  color="secondary"
                                  style={{
                                    position: "absolute",
                                    top: "-8px",
                                    right: "-6px",
                                    zIndex: 0,
                                    backgroundColor: "#ffffff",
                                  }}
                                  onClick={(e) => handleCheckboxId(e)}
                                />
                              ) : null}
                            </ImageListItem>
                            {/* <Typography
                              style={{
                                fontWeight: "600",
                                textAlign: "center",
                                fontFamily: '"Roboto Condensed", sans-serif',
                              }}
                            >
                              {
                                displayImageData?.included?.find(
                                  (elem) =>
                                    elem.id ==
                                      item?.relationships?.createdBy?.data
                                        ?.id &&
                                    elem.type ==
                                      item?.relationships?.createdBy?.data?.type
                                )?.attributes?.name
                              }
                            </Typography> */}
                          </Zoom>
                        );
                      })}
                    </ImageList>
                    {loadMoreForImage}
                  </>
                ) : (
                  <Typography
                    style={{
                      width: "100%",
                      color: "#221C46",
                      textAlign: "center",
                      fontFamily: '"Roboto Condensed", sans-serif',
                    }}
                  >
                    There is no Photos!
                  </Typography>
                )}
              </Grid>
            )}

            {isImage == 2 && (
              <Grid style={{ padding: "0 15px" }}>
                {displayVideoData?.data?.length > 0 ? (
                  <>
                    <ImageList
                      sx={{ marginBottom: 3 }}
                      cols={3}
                      rowHeight={160}
                    >
                      {displayVideoData?.data.map((item) => {
                        return (
                          <Zoom key={item.id}>
                            <ImageListItem
                              key={item.id}
                              cols={1}
                              rows={1}
                              style={{ marginBottom: 15, position: "relative" }}
                            >
                              <video
                                src={`${IMAGE_URL}/${item.attributes.thumbUrl}`}
                                controls
                                playsInline
                                style={{
                                  height: 150,
                                  width: 150,
                                }}
                              />
                              {isDeleteCheckBox &&
                                item?.relationships?.createdBy?.data?.id ==
                                  currentUserId && (
                                  <Checkbox
                                    color="secondary"
                                    value={item.id}
                                    style={{
                                      position: "absolute",
                                      top: "-8px",
                                      right: "-6px",
                                      zIndex: 0,
                                      backgroundColor: "#ffffff",
                                    }}
                                    onClick={(e) => handleCheckboxId(e)}
                                  />
                                )}
                              {/* <Typography
                                style={{
                                  fontWeight: "600",
                                  textAlign: "center",
                                  fontFamily: '"Roboto Condensed", sans-serif',
                                }}
                              >
                                {
                                  displayVideoData?.included?.find(
                                    (elem) =>
                                      elem.id ==
                                        item?.relationships?.createdBy?.data
                                          ?.id &&
                                      elem.type ==
                                        item?.relationships?.createdBy?.data
                                          ?.type
                                  )?.attributes?.name
                                }
                              </Typography> */}
                            </ImageListItem>
                          </Zoom>
                        );
                      })}
                    </ImageList>
                    {loadMoreForVideo}
                  </>
                ) : (
                  <Typography
                    style={{
                      width: "100%",
                      color: "#221C46",
                      textAlign: "center",
                      fontFamily: '"Roboto Condensed", sans-serif',
                    }}
                  >
                    There is no Videos!
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        ) : (
          <HighlightAlbum
            mediableId={albumId}
            mediableType="Album"
            setAlbumId={setAlbumId}
            setIsAlbumOpen={setIsAlbumOpen}
            currentUserId={currentUserId}
          />
        )}
      </Grid>
      <HighlightModel
        isMediaModelOpen={isMediaModelOpen}
        fileType={fileType}
        handleMediaModelClose={handleMediaModelClose}
        token={token}
        seIsMedianModelOpen={seIsMedianModelOpen}
        mediableType={mediableType}
        mediableId={GALLERY_ID}
        isImage={isImage}
        setDisplayImageData={setDisplayImageData}
        displayImageData={displayImageData}
        setDisplayVideoData={setDisplayVideoData}
        displayVideoData={displayVideoData}
      />
    </ErrorBoundary>
  );
};

export default Highlights;
