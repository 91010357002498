import { Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react'

const AgendaDaysItem = ({ItineraryDay1,
    ItineraryDay2,
    ItineraryDay3,SpeakerDay1,
    SpeakerDay2,
    SpeakerDay3}) => {
    const [daysBtnTabIndex, setdaysBtnTabIndex] = useState(0);
  return (
    <Grid className='days_btn_container'>
                <Grid className='days_btns'>
                {daysBtnTabIndex === 0 ?
                <Button onClick={()=> setdaysBtnTabIndex(0)} style={{width:"32%", padding:"6px 25px",fontSize:"20px",color:"#ffffff",fontWeight:"700",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif',borderBottom:"5px solid #221C46"}}>Day 1</Button>
                : <Button onClick={()=> setdaysBtnTabIndex(0)} style={{width:"32%", padding:"6px 25px",fontSize:"20px",color:"#ffffff",fontWeight:"700",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif'}}>Day 1</Button>}

                {daysBtnTabIndex === 1 ?
                <Button onClick={()=> setdaysBtnTabIndex(1)} style={{width:"32%", padding:"6px 25px",fontSize:"20px",color:"#ffffff",fontWeight:"700",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif',borderBottom:"5px solid #221C46"}}>Day 2</Button>
                : <Button onClick={()=> setdaysBtnTabIndex(1)} style={{width:"32%", padding:"6px 25px",fontSize:"20px",color:"#ffffff",fontWeight:"700",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif'}}>Day 2</Button>}
                
                {daysBtnTabIndex === 2 ?
                <Button onClick={()=> setdaysBtnTabIndex(2)} style={{width:"32%", padding:"6px 25px",fontSize:"20px",color:"#ffffff",fontWeight:"700",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif',borderBottom:"5px solid #221C46"}}>Day 3</Button>
                : <Button onClick={()=> setdaysBtnTabIndex(2)} style={{width:"32%", padding:"6px 25px",fontSize:"20px",color:"#ffffff",fontWeight:"700",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif'}}>Day 3</Button>}
                </Grid>
                <Grid className='days_btn_itms_container'>
                  {
                    daysBtnTabIndex === 0 && 
                    <Grid className='days_item_container'>
                    {ItineraryDay1 && ItineraryDay1.map(el=> {
                    return <Grid className='days_item_para'>
                    <Typography style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"18px",color:"#221C46"}}>{el}</Typography>
                    {/* <Typography></Typography>
                    <Typography></Typography> */}
                    </Grid>})}
                </Grid>  
                  }
                  {
                    daysBtnTabIndex === 1 && <Grid className='days_item_container'>
                    {ItineraryDay2 && ItineraryDay2.map(el=> {
                    return <Grid className='days_item_para'>
                    <Typography style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"18px",color:"#221C46"}}>{el}</Typography>
                    {/* <Typography></Typography>
                    <Typography></Typography> */}
                    </Grid>})}
                </Grid>   
                  }
                  {
                    daysBtnTabIndex === 2 && <Grid className='days_item_container'>
                    {ItineraryDay3 && ItineraryDay3.map(el=> {
                    return <Grid className='days_item_para'>
                    <Typography style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"18px",color:"#221C46"}}>{el}</Typography>
                    {/* <Typography></Typography>
                    <Typography></Typography> */}
                    </Grid>})}
                </Grid>   
                  }
                  {
                    daysBtnTabIndex === 0 && 
                    <Grid className='days_item_container'>
                    {SpeakerDay1 && SpeakerDay1.map(el=> {
                    return <Grid className='speaker_item_para'>
                    <Typography style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"18px",color:"#221C46"}}>{el}</Typography>
                    {/* <Typography></Typography>
                    <Typography></Typography> */}
                    </Grid>})}
                </Grid>  
                  }
                  {
                    daysBtnTabIndex === 1 && <Grid className='days_item_container'>
                    {SpeakerDay2 && SpeakerDay2.map(el=> {
                    return <Grid className='speaker_item_para'>
                    <Typography style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"18px",color:"#221C46"}}>{el}</Typography>
                    {/* <Typography></Typography>
                    <Typography></Typography> */}
                    </Grid>})}
                </Grid>   
                  }
                  {
                    daysBtnTabIndex === 2 && <Grid className='days_item_container'>
                    {SpeakerDay3 && SpeakerDay3.map(el=> {
                    return <Grid className='speaker_item_para'>
                    <Typography style={{fontFamily:'"Roboto Condensed", sans-serif',fontSize:"18px",color:"#221C46"}}>{el}</Typography>
                    {/* <Typography></Typography>
                    <Typography></Typography> */}
                    </Grid>})}
                </Grid>   
                  }
                </Grid>
    </Grid>
  )
}

export default AgendaDaysItem;
