import React from 'react';
import { Grid, Button } from '@mui/material';

const Btn = ({ btnName, onclicked }) => {
    return (
        <Grid style={{ width: "100%", margin: "35px 0 20px 0" }}>
            <Button onClick={onclicked} style={{ width: "100%", color: "#221C46", fontWeight: "600", fontSize: "18px", background: "#00EB5E", padding: "10px 0", textTransform: "none" }}>{btnName}</Button>
        </Grid>
    )
}

export default Btn;