import React, { useEffect, useState } from 'react';
import "../ComponentStyles/commentcard.css";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Avatar, Button, Card, CardContent, CardHeader, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from "../Assets/delete.png"
// import ReadMoreReadLess from './ReadMoreReadLess';
import { red } from '@mui/material/colors';
import Timestamp from './Timestamp';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

let currentUserAvatar = JSON.parse(localStorage.getItem("currentUserAvatarUrl"));
let currentUserName = localStorage.getItem("currentUserName");


const CommentCard = ({cardId,handleCommentClose,editOpen,token,
    setLike,Like}) => {

    const [commentData, setCommentData] = React.useState({
        data: [],
        included: [],
        links: {},
      });
      const [commentMsg,setCommentMsg] = useState();
      const [page, setPage] = React.useState(1);
      const [textareaValidate,setTextareavalidate] = useState("")
      const [isDeleteConfirm,setIsDeleteConfirm] = useState(false)
      const [oldOnDeleteId,setOldOnDeleteID] = useState(null)

      useEffect(()=>{
        try {
          fetch(
            `https://api.sociana.in/api/v1/oc/146/get_comments_with_pagination?commentable_id=${cardId}&commentable_type=Post&page=${page}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => {
              if (res.status == 200) {
                return (
                  res.json()
                );
              }
              return res.json();
            })
            .then((results) => {
                if(results){
                setCommentData({
                  data: commentData?.data.concat(results?.data),
                  included: commentData?.included.concat(results?.included),
                  links: results?.links,
                })
                }
            });
        } catch (error) {
          console.error(error);
        }
      },[page]);

      const getNextPage = (str) => {
        const str1 = str.split("=");
        console.log("str1",str1)
        const res = str1[3].split("&");
        console.log("res",res)
        return parseInt(res[0], 10);
      };
    
      const onLoadMore = () => {
        if (!!commentData?.links?.next) {
            console.log("links",commentData?.links)
          const pageNumber = getNextPage(commentData?.links?.next);
          setPage(pageNumber);
        }
      };

      const loadMore = React.useMemo(() => {
        return !!commentData?.links?.next ? (
          <Grid textAlign="center" style={{ width: "100%" }}>
            <Button
              style={{
                marginBottom: "20px",
                cursor: "pointer",
                padding: "5px 20px",
                backgroundColor: "#221c46",
                color: "white",
                borderRadius: "10px",
              }}
              onClick={onLoadMore}
            >
              load more
            </Button>
          </Grid>
        ) : null;
      }, [commentData?.links?.next]);


      const handleCommentMsgChange = (event)=>{
          setCommentMsg(event.target.value)  
      }

      
  const handleSentComments = (id)=>{
    try{
      setTextareavalidate("")
      const formData = new FormData();
      if(commentMsg?.length > 0 && commentMsg?.length < 350){
        formData.append("comment[message]",commentMsg)
      formData.append("comment[commentable_id]",id)
      formData.append("comment[commentable_type]","Post")
      fetch(
        `https://api.sociana.in/api/v1/oc/146/comments`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        ).then(res =>{
          if (res.status == 200 || res.status == 201) {
            return (
                // setSnackbarData({
                //     message: 'Post created Successfully.',
                //     type: 'success'
                // }),
                res.json()
            );
        }}
        ).then(result=> {
          if(result){
            console.log(result)
            let PostObj = result.included.find(el=>
              el.type == "posts"
            );
            setLike({...Like,totalComment: PostObj?.attributes?.totalComment})
            setCommentData({
              data: [result?.data,...commentData?.data],
              included: [...result?.included,...commentData?.included],
            })
            setCommentMsg("")
          }
      })
      }else{
        setTextareavalidate("Please ensure comment is less than 350 characters")
      }
  }catch{
    // setSnackbarData({
    //     message: 'Something Went Wrong.',
    //     type: 'error'
    // });
  console.log("error") 
  }
  }

  const onDeleteComment=(id)=>{
    console.log("previous id",id)
          setIsDeleteConfirm(true)
          setOldOnDeleteID(id)    
  }

  const handleDeleteCommentClose = ()=>{ setIsDeleteConfirm(false)}

  const handleDeleteCommentDelete = ()=>{
    if(oldOnDeleteId !== null){
      setIsDeleteConfirm(false)
      try{
        fetch(
        `https://api.sociana.in/api/v1/oc/146/comments/${oldOnDeleteId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        ).then(res =>{
          if (res.status == 200 || res.status == 201) {
            return (
                // setSnackbarData({
                //     message: 'Post created Successfully.',
                //     type: 'success'
                // }),
                res.json()
            );
        }}
        ).then(result=> {
          if(result){
            let PostObj = result.included.find(el=>
                el.type == "posts"
              );
            let filterData = commentData?.data?.filter(item=> item?.id !== result?.data?.id)
            // let filterIncluded = result.included.find(el=>
            //     el.type == "posts"
            //   );
            setLike({...Like,
                totalComment: PostObj?.attributes?.totalComment})
            setCommentData({
                data: [...filterData],
                included: [...commentData?.included],
                links: commentData?.links,
            })
          }
      })
    }catch{
      // setSnackbarData({
      //     message: 'Something Went Wrong.',
      //     type: 'error'
      // });
    console.log("error") 
    }
    }
    setOldOnDeleteID(null)
  }
  console.log("newdeleteid",oldOnDeleteId)
  return (
    <>
    <BootstrapDialog
          onClose={handleCommentClose}
          aria-labelledby="customized-dialog-title"
          open={editOpen}
        >
      <DialogActions
            style={{
              width:"100%",
              maxWidth:"400px",
              display: "flex",
              padding:"10px 5px",
              margin:"0",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position:"relative",
              background:"#221c46"
            }}
          >
            <Grid style={{padding:"10px 0 15px 0"}}>
            {commentData?.data?.length > 0 ? (
            <>
            <Grid className='card_container'>
              {commentData?.data?.map((item) => (
    <Grid
        // sx={{
        //   maxWidth: 400,
        //   width: "100%",
        // //   boxShadow: "0 0 2px 0.5px gray",
        //   backgroundColor: "whitesmoke",
        //   marginBottom:"15px",
        //   borderRadius:"10px"
        // }}
        className='comment_card'
      >
        {/* <CardHeader
          avatar={ */}
          <Grid className='comment_box_header'>
          <Grid className='comment_box_avatar'>
            <Avatar
              sx={{ bgcolor: red[100], width: "50px", height: "50px" }}
              aria-label="recipe"
            >
              {commentData?.included?.find(index=>
                      item?.relationships?.user?.data?.id === index.id 
                   )?.attributes?.avatarUrl ? (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={`https://api.sociana.in//${commentData?.included?.find(index=>
                      item?.relationships?.user?.data?.id === index.id 
                   )?.attributes?.avatarUrl}`}
                  alt="Logo"
                />
              ) : (
                commentData?.included?.find(index=>
                      item?.relationships?.user?.data?.id === index.id 
                       )?.attributes?.name.substring(0, 1)
              )}
            </Avatar>
          </Grid>
          {/* }
          title={<><b style={{marginRight:"5px"}}>{commentData?.included?.find(index=>
            item?.relationships?.user?.data?.id === index.id 
             )?.attributes?.name.substring(0, commentData?.included?.find(index=>
              item?.relationships?.user?.data?.id === index.id 
               )?.attributes?.name.indexOf(" "))}</b>
               <b style={{marginRight:"7px"}}>{commentData?.included?.find(index=>
                item?.relationships?.user?.data?.id === index.id 
                 )?.attributes?.name.substring(commentData?.included?.find(index=>
                  item?.relationships?.user?.data?.id === index.id 
                   )?.attributes?.name.indexOf(' ') + 1).substring(0, 1)}</b>{item?.attributes?.isCommentByCurrentUser && <span style={{background:"#00EB5E",padding:"3px 3px",color:"#221C46",fontWeight: "600",
                   fontFamily: "'Roboto Condensed', sans-serif",marginRight:"10px"}}>you</span>}<Timestamp  postTimesstamp={item?.attributes?.createdAt}/></>}
        /> */}
        <Grid className='comment_box_name_date'>
          <Typography><b style={{marginRight:"5px"}}>{commentData?.included?.find(index=>
            item?.relationships?.user?.data?.id === index.id 
             )?.attributes?.name.substring(0, commentData?.included?.find(index=>
              item?.relationships?.user?.data?.id === index.id 
               )?.attributes?.name.indexOf(" "))}</b>
               <b style={{marginRight:"7px"}}>{commentData?.included?.find(index=>
                item?.relationships?.user?.data?.id === index.id 
                 )?.attributes?.name.substring(commentData?.included?.find(index=>
                  item?.relationships?.user?.data?.id === index.id 
                   )?.attributes?.name.indexOf(' ') + 1).substring(0, 1)}</b></Typography>
          {item?.attributes?.isCommentByCurrentUser && <Typography style={{background:"#00EB5E",padding:"2px 4px",color:"#221C46",fontWeight: "600",fontFamily: "'Roboto Condensed', sans-serif",marginRight:"10px",letterSpacing:"1px"}}>You</Typography>}
          <Typography><Timestamp postTimesstamp={item?.attributes?.createdAt}  /></Typography>
        </Grid>
          </Grid>
        <CardContent style={{ padding: "10px 0 0 10px" }}>
            {item?.attributes?.message && (
              <Typography style={{fontSize: "17px",color:"#67727E",
              fontWeight: "400",
              fontFamily: "'Roboto Condensed', sans-serif"}}>{item?.attributes?.message}</Typography>
            )}
        </CardContent>
        {item?.attributes?.isCommentByCurrentUser == true && <CardContent style={{ padding: "0" }}>
            <Button style={{color:"#ED6368"}} onClick={()=>onDeleteComment(item?.id)}><img style={{marginRight:"3px"}} src={DeleteIcon} alt='Delete'/>Delete</Button>
        </CardContent>}
      </Grid>
              ))}
              {loadMore}
              </Grid>
            </>
          ) : (
            <Grid style={{width:"100%",height:"200px",padding:"10px"}}>
              <Typography style={{color:"#ffffff"}}>no comment in this post</Typography>
            </Grid>
          )} 
      <Grid className='card_textarea_container'>
      <Grid
      className= "card_textarea"
      >
        <CardContent className='card_textarea_input'>
        <textarea
                style={{width:"100%",height:"80px",outline:"none",padding:"10px 15px",overflowY:"scroll",resize:"none",overflow:"auto",marginBottom:"5px",border:"0.5px solid #E9EBF0",fontSize:"20px",background:"none"}}
                  id="raised-button-file"
                  placeholder="Add a comment…"
                  type="text"
                  onChange={handleCommentMsgChange}
                  // name="Caption"
                  value={commentMsg}
                />
                <Typography style={{color:"#ED6368",fontSize:"13px"}}>{textareaValidate}</Typography>
        </CardContent>
        <CardContent  className='card_textarea_button'>
        <CardHeader style={{padding:"0 0 2px 2px"}}
          avatar={
            <Avatar
              sx={{ bgcolor: red[100]}}
              aria-label="recipe"
            >
              {currentUserAvatar ? (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={`https://api.sociana.in//${currentUserAvatar}`}
                  alt="Logo"
                />
              ) : (
                currentUserName.substring(0, 1)
              )}
            </Avatar>
          }
        />
        <Button style={{background:"#00EB5E",padding:"5px 7px",color:"#221C46",
              fontWeight: "600",
              fontFamily: "'Roboto Condensed', sans-serif"}}  onClick={() =>handleSentComments(cardId)}>send</Button>
        </CardContent>
      </Grid>
          </Grid>
          
          </Grid>
          </DialogActions>
          </BootstrapDialog>

    <BootstrapDialog
          onClose={handleDeleteCommentClose}
          aria-labelledby="customized-dialog-title"
          open={isDeleteConfirm}
        >
      <DialogActions
            style={{
              width:"100%",
              maxWidth:"400px",
              display: "flex",
              padding:"10px 5px",
              margin:"0",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position:"relative",
            }}
          >
       <Grid className='delete_popup_box'>
       <CardContent className='delete_popup_static'>
            <Typography className='delete_popup_title'>Delete comment</Typography>
            <Typography className='delete_popup_subtitle'>Are you sure you want to delete this comment? This will remove the comment and can’t be undone.</Typography>
        </CardContent>
        <CardContent className='delete_popup_btns'>
            <Button className='delete_popup_btn1'  onClick={handleDeleteCommentClose}>NO, CANCEL</Button>
            <Button className='delete_popup_btn2'  onClick={()=> handleDeleteCommentDelete()}>YES, DELETE</Button>
        </CardContent>
       </Grid>
          </DialogActions>
          </BootstrapDialog>
    </>
  )
}

export default CommentCard;


