import React, { useEffect, useState } from 'react';
import "./login.css";
import Logo from "../../Assets/homelogo.jpeg";
import { Grid, Typography, Box, TextField, FormControlLabel, Checkbox, Snackbar, Alert } from '@mui/material';
import Btn from '../../Components/Button';
import LoginOtp from './LoginOtp';
import { validateEmail, validateMobile } from "../../Components/Validation";
import { useNavigate } from 'react-router-dom';


const validateLoginData = (values) => {
    const error = {};
    if (!values.loginData) {
        error.loginData = 'Email is required';
    } else if (values.loginData && !validateEmail(values.loginData)) {
        error.loginData = 'Enter a valid Email';
    }
    return error;
};

const PERSONALIZED_APP_ID = "a589d29a0aa70d0f3cd83eac37cee144ab29e7c81f43afb2a5e35d79c6631019a951dc35af575b6601a888705243e165b7637f17e8d24b2a6c2d7a692ce24ce0";

const Login = () => {

    const [isVerificationOpen, setIsVerificationOpen] = useState(false);
    const [loginFormData, setLoginFormData] = useState({ loginData: ""});
    const [errorMessages, setErrorMessages] = useState({});
    const [snackbarData, setSnackbarData] = useState({
        message: '',
        type: 'success'
    });
    const [otpData, setOtpData] = useState({});

    const navigate = useNavigate();

    useEffect(()=>{
        let login = localStorage.getItem("login");
        if(login){
            navigate("/")
        }
    })

    const handleChange = (e) => {
        try {
            const LoginValue = e?.target?.value;
            const inputName = e?.target?.name;
            if (Object.keys(errorMessages).length > 0) {
                setErrorMessages({});
            }
            setLoginFormData({
                ...loginFormData,
                [inputName]: LoginValue
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleContinue = () => {
        try {
            const validateObj = validateLoginData(loginFormData);
            if (Object.keys(validateObj).length > 0) {
                setErrorMessages(validateObj);
            } else {
                setErrorMessages({});

                const body = {
                    verification: {
                        custom_app_id: PERSONALIZED_APP_ID
                    }
                };
                if (validateEmail(loginFormData.loginData)) {
                    body.verification['email'] = loginFormData.loginData;
                }

                fetch('https://api.sociana.in/api/v3/generate_code', {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: { 'Content-Type': 'application/json' }
                })
                    .then((response) => {
                        if (response.status == 200 || response.status == 201) {
                            return (
                                setSnackbarData({
                                    message: 'OTP Send Successfully.',
                                    type: 'success'
                                }),
                                setIsVerificationOpen(true),
                                response.json()
                            );
                        }
                        return (
                            setSnackbarData({
                                message: 'Something Went Wrong.',
                                type: 'error'
                            }),
                            response.json()
                        );
                    })
                    .then((results) => {
                        setOtpData({ ...results?.data?.attributes });
                    });
            }
        } catch (error) {
            setSnackbarData({
                message: 'Something Went Wrong.',
                type: 'error'
            });
            console.error(error);
        }
    }

    const handleClose = () => {
        setSnackbarData({
            message: '',
            type: 'success'
        });
    };

    const backLoginPage = () => {
        setIsVerificationOpen(false);
    }

    // console.log("chandan", otpData);

    return (
        <>
            {
                isVerificationOpen ?
                    <LoginOtp onclicked={backLoginPage} loginFormData={loginFormData} otpData={otpData} PERSONALIZED_APP_ID={PERSONALIZED_APP_ID} />
                    :
                    <Grid container className='login_container' style={{ width: "100%", height: "100vh", background: "var(--bg)",margin:"0" }}>
                        <Grid style={{ width: "100%", textAlign: "center" }}>
                        <Grid style={{ width: "100%", background: "#221c46", padding: "20px 25px",display:"flex",justifyContent:"center" }}>
                    <Grid style={{ width: "400px" }}>
                    <img style={{width:"100%"}} src={Logo} alt="Logo" />
                    </Grid>
                </Grid>
                            <Grid className='login_data'>
                                <Typography variant='h3' style={{color:"#221c46"}}>
                                    {/* <h2 className='login_title'></h2> */}
                                    Log in
                                </Typography>
                                <Grid style={{ textAlign: "start", marginTop: "20px" }}>
                                    <Typography variant='subtitle1' style={{color:"#221c46"}}>
                                        Let’s start with your <b>Email</b>
                                    </Typography>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { width: '100%', background: "#ffffff", borderRadius: "4px", margin: "10px 0 3px 0" },
                                        }}
                                    >
                                        <TextField id="outlined-basic" variant="outlined" onChange={handleChange} value={loginFormData.loginData} name="loginData" />
                                    </Box>
                                    <Typography variant='subtitle' style={{ color: "red" }}>
                                        {errorMessages.loginData}
                                    </Typography>
                                </Grid>
                                <Btn btnName="Continue" onclicked={handleContinue} />
                                {/* <Grid style={{ width: "100%", textAlign: "center" }}>
                                    <FormControlLabel control={<Checkbox style={{ color: "#ffffff" }} />} label="I allow communication via Whatsapp" />
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
            }
            <Snackbar
                open={!!snackbarData.message}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleClose}
                style={{ border: '2px solid black', borderRadius: 5 }}
                message={snackbarData.message}>
                <Alert severity={snackbarData.type}>{snackbarData.message}</Alert>
            </Snackbar>
        </>

    )
}

export default Login;