import React, { useState } from 'react';
import "./agenda.css";
import { useNavigate } from 'react-router-dom';
import {
    Grid, Button
} from '@mui/material';
import FAQSLogo from "../../Assets/homelogo.jpeg";
import leftArrow from "../../Assets/AgendaIcon.png";
import AgendaDaysItem from '../../Components/AgendaDaysItem';

const Agenda = () => {

  const [btnTabIndex, setBtnTabIndex] = useState(0);

  const navigate = useNavigate();
  const onclicked = () => {
        navigate("/");
    }

    const ItineraryDay1 = [
      "Arrival as per respective flights","Check In Registration","Lunch on Arrival at Venue","Conference Day 1","Break","Welcome Dinner"
    ]
    const ItineraryDay2 = [
      "Conference","Lunch","Outdoor Activity","Break","Awards Night"
    ]
    const ItineraryDay3 = [
      "Check Out","Conference","Lunch","Depart to Airport"
    ]
    
    // const SpeakerDay1 = [
    //   "COMMING SOON"
    // ]
    // const SpeakerDay2 = [
    //   "COMMING SOON"
    // ]
    // const SpeakerDay3 = [
    //   "COMMING SOON"
    // ]

  return (
    <Grid container style={{marginBottom:"60px"}}>
            <Grid style={{ width: "100%", background: "#221c46", padding: "20px 25px",display:"flex",justifyContent:"center" }}>
                    <Grid style={{ width: "400px" }}>
                    <img style={{width:"100%"}} src={FAQSLogo} alt="Logo" />
                    </Grid>
                </Grid>
            <Grid style={{ width: "100%", padding: "20px 20px", display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                <Button onClick={onclicked} style={{ padding: "10px 10px", textTransform: "none", background: "none", color: "#ffffff", fontSize: "25px", fontWeight: "500", fontFamily: "'Roboto Condensed', sans-serif" }}><img style={{ marginRight: "10px" }} src={leftArrow} alt='<-' /><b style={{color:"#221C46"}}>Agenda</b></Button>
            </Grid>
            <Grid className='agenda_main'>
              <Grid className='agenda_container'>
                <Grid className='agenda_btns'>
                {btnTabIndex === 0 ?
                <Button onClick={()=> setBtnTabIndex(0)} style={{padding:"6px 25px",fontSize:"20px",color:"#221C46",fontWeight:"700",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif',background:"white"}}>Itinerary</Button>
                : <Button onClick={()=> setBtnTabIndex(0)} style={{padding:"6px 25px",fontSize:"20px",color:"#221C46",fontWeight:"700",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif'}}>Itinerary</Button>}

                {/*{btnTabIndex === 1 ?
                <Button onClick={()=> setBtnTabIndex(1)} style={{padding:"6px 25px",fontSize:"20px",color:"#221C46",fontWeight:"700",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif',background:"white"}}>Speakers</Button>
                : <Button onClick={()=> setBtnTabIndex(1)} style={{padding:"6px 25px",fontSize:"20px",color:"#221C46",fontWeight:"700",textTransform:"none",fontFamily:'"Roboto Condensed", sans-serif'}}>Speakers</Button>}*/}
                </Grid>
              </Grid>
              <Grid className='btn_itms_container'>
                  {
                    btnTabIndex === 0 && <AgendaDaysItem 
                    ItineraryDay1={ItineraryDay1}
                    ItineraryDay2={ItineraryDay2}
                    ItineraryDay3={ItineraryDay3}/> 
                  }
                  {/* {
                    btnTabIndex === 1 && <AgendaDaysItem 
                    SpeakerDay1={SpeakerDay1}
                      SpeakerDay2={SpeakerDay2}
                      SpeakerDay3={SpeakerDay3}
                    /> 
                  } */}
                </Grid>
            </Grid>
    </Grid>
  )
}

export default Agenda;
