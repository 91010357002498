import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

const ProtectedRout = (props) => {

    const {Component} = props;
    const navigate = useNavigate();

    useEffect(()=>{
        let login = localStorage.getItem("login");
        if(!login){
            navigate("/login")
        }
    });

  return (
    <div>
      <Component />
      <Footer />
    </div>
  )
}

export default ProtectedRout;
