import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import QALogo from "../../Assets/homelogo.jpeg";
import leftArrow from "../../Assets/AgendaIcon.png";
import EmptyData from "../../Components/EmptyData";
import "./noti.css";

const Notification = () => {

  let token = localStorage.getItem("token");
  const [notificationData, setNotificationData] = useState({data:[],links:{}});
    const [page, setPage] = React.useState(1);

  const navigate = useNavigate();

    const onclicked = () => {
        navigate("/");
    }

    useEffect(()=>{
      // console.log("page",page)
      try{
          
          fetch(
          `https://api.sociana.in/api/v1/oc/146/get_occasion_based_notifications?page=${page}`,
          {
              method: 'GET',
              headers: {
                  Authorization: `Bearer ${token}`
              }
          }
      ).then((res)=>{
          return (
              res.json()
          );
      }).then((results)=>{
          if(results){
            setNotificationData({
                  data:notificationData?.data.concat(results?.data)
                 ,links:results?.links })
          }
      });
  
  }catch (err) {
      console.log(err);
  }
  
  },[page])

  console.log(notificationData.data)

  const getNextPage = (str) => {
    const str1 = str.split("=");
    const res = str1[1].split("&");
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (!!notificationData?.links?.next) {
      const pageNumber = getNextPage(notificationData?.links?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = React.useMemo(() => {
    return !!notificationData?.links?.next ? (
      <Grid textAlign="center" style={{ width: "100%" }}>
        <Button
          style={{
            marginBottom: "20px",
            cursor: "pointer",
            padding: "5px 20px",
            backgroundColor: "#221c46",
            color: "white",
            borderRadius: "10px",
          }}
          onClick={onLoadMore}
        >
          loading more
        </Button>
      </Grid>
    ) : null;
  }, [notificationData?.links?.next]);

  return (
    <Grid container  style={{marginBottom:"60px"}}>
           <Grid style={{ width: "100%", background: "#221c46", padding: "20px 25px",display:"flex",justifyContent:"center" }}>
                    <Grid style={{ width: "400px" }}>
                    <img style={{width:"100%"}} src={QALogo} alt="Logo" />
                    </Grid>
                </Grid>
            <Grid style={{ width: "100%", padding: "20px 20px", display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <Button onClick={onclicked} style={{ padding: "10px 10px", textTransform: "none", background: "none", color: "#ffffff", fontSize: "25px", fontWeight: "500", fontFamily: "'Roboto Condensed', sans-serif" }}><img style={{ marginRight: "10px" }} src={leftArrow} alt='<-' /><b style={{color:"#221C46"}}>Notification</b></Button>
            </Grid>
            <Grid style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:"0 10px"}}>
{notificationData?.data?.length > 0 ? 
  <> 
{notificationData?.data?.map(el=>{
 return <Grid className='notifi_card'>
      <Typography style={{marginBottom:"5px"}}>
        <span style={{background:"#221c46",color:"#ffffff",padding:"5px 10px",borderRadius:"8px"}}>{new Date(el?.attributes?.createdAt).toDateString()}</span>
      </Typography>
   <Typography style={{paddingLeft:"10px"}}>
    {el?.attributes?.message}
  </Typography>
</Grid>

})}
{loadMore}
</>
:
 <EmptyData color="#ffffff"/> }
            </Grid>
            </Grid>
  )
}

export default Notification;

// ({
//   notificationData?.data?.map(el=>{
//     <Grid style={{width:"500px", margin:"10px auto",background:"whitesmoke",color:"black",padding:"10px 10px",borderRadius:"8px"}}>
//       <Typography>
//         {new Date(el.createdAt).toDateString()}
//       </Typography>
//     <Typography>
//       {el.message}
//     </Typography>
//   </Grid>
//   })
//   {loadMore}
// })  
